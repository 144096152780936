import React, {useEffect, useState} from 'react';
import {DefaultSeo} from 'next-seo';
import {useRouter} from 'next/router';
import dynamic from 'next/dynamic';
import NProgress from 'nprogress';
import {SplitFactoryProvider} from '@splitsoftware/splitio-react';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';

/* Libs/integrations */
import {getKeyAJSKeyForConfig, index} from '@integrations/split';
import {queryClient, QueryClientProvider, HydrationBoundary} from '@integrations/reactquery';
import ClassSegment from '@integrations/segment';
import ClientBugsnag, {logWarning} from '@integrations/bugsnag';
import * as gtag from '@integrations/gtag';

/* Components */
import AppLevelSideEffects from '@components/AppLevelSideEffects';
import {LoginModalManager, LoginPasscodeModal} from '@scenes/LoginPage/Modal';

/* Utils */
import {sleep} from '@utils/helpers';
import {GlobalSettingsLoad, GlobalSettingsUnLoad} from '@components/App/globalConcerns/app.globalsettings';

/* App Store: BaseLayoutContent */
import {useResponsiveActions} from '@store/client/store.responsive';
import {useAppStoreActions} from '@store/client/store.app';

/* Queries/Mutations */
import {cartQueryKeys} from '@store/queries/cart/queries.keys';
import {userQueryKeys} from '@store/queries/user/queries.keys';

/* Utils */
import {updateContingencies} from '@store/queries/user/user.utils';
import {setUserPlanBrandingAndCookies} from '@integrations/partners/branding/branding.utils';

/* Types and Interfaces */
import {IMyAppProps} from 'src/pages/_app';

/* Misc */
import SEO from '../../../next-seo.config';
import useIntervalConditionally from '@hooks/useIntervalConditionally';

const ErrorComponent = dynamic(() => import('@components/Error/DefaultErrorPage'), {ssr: false});

/* Client Setup */
const ClaimStoreCreditsModal = dynamic(() => import('@components/Modals/ClaimStoreCreditsModal'), {ssr: false});
const ErrorBoundary = ClientBugsnag();
NProgress.configure({showSpinner: false});

/*
 * Allows us to use devTools in Production
 * */
const ReactQueryDevToolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(devtools => ({
    default: devtools.ReactQueryDevtools,
  })),
);

/**
 * @remarks
 * Please NOTE: If you have a page that does NOT require split or any external apis, we can
 * Start thinking about loading this file in a conditional
 *
 * @param {IMyAppProps} props
 * @returns {any}
 * @constructor
 */

const App = (props: IMyAppProps) => {
  /* State: Global Base Level */
  const {setSplitAppLevel} = useAppStoreActions();
  const {setInitializeResponsive} = useResponsiveActions();

  /* Local */
  const {Component, pageProps} = props;

  /* local state */
  const [, setAjsAnonymousId] = useState(index.core.key);
  const [showDevTools, setShowDevTools] = useState(false);
  const [shouldRun, setShouldRun] = useState(false);

  /* Hooks */
  const router = useRouter();
  /* Poll for AJS Anonymous id for Split. We should set this ourslves if doesn't exist. Will create ticket */
  useIntervalConditionally(
    () => {
      const hasAjsCookie = getKeyAJSKeyForConfig() !== 'anonymous';
      if (hasAjsCookie) {
        setSplitAppLevel(false);
        setAjsAnonymousId(getKeyAJSKeyForConfig());
        return true; // This will stop the interval once the anonymousId is found
      }
      return false; // This will keep the interval running
    },
    200,
    20, // we'll wait for 4 seconds
    shouldRun,
  );

  /* Methods */
  /* Start route change */
  const handleRouteStart = (url: string) => {
    NProgress.start();

    // This allows us to extract out global concerns. When a page is left/leaving.
    GlobalSettingsUnLoad();
  };

  /* Complete route change */
  const handleRouteComplete = () => {
    NProgress.done();
  };

  /* Fire initial site loading page tracking */
  useEffect(() => {
    if (router.isReady) {
      ClassSegment?.page(router.asPath).done();
      gtag.pageview(router.asPath);
    }
  }, [router.asPath]);

  /* Listeners */
  router.events?.on('routeChangeStart', handleRouteStart);
  router.events?.on('routeChangeComplete', handleRouteComplete);

  useEffect(() => {
    (async () => {
      setInitializeResponsive();
      /*
        To allow for perf gains with ssg (we don't use ssr here) so get session when user hits.
        This is a modified session for speed. We'll revisit this when we get into /cart
      */
      const data = {user: {}, cart: {}, auth: {}};
      let user;
      let cart;
      let auth;

      try {
        const response = await fetch('/api/_nextassets_/session');
        const jsonData = await response.json();
        user = jsonData.user;
        cart = jsonData.cart;
        auth = jsonData.auth;
      } catch (e) {
        let err = e as Error;
        if (!navigator.onLine) {
          err = new Error('Failed to fetch: Offline');
        }
        logWarning(err);
      }

      queryClient.setQueryData(userQueryKeys.auth, auth || data.auth);
      await sleep(0);
      queryClient.setQueryData(userQueryKeys.auth, auth || data.auth);
      queryClient.setQueryData(cartQueryKeys.all, cart || data.cart);
      queryClient.setQueryData(userQueryKeys.all, user || data.user);

      /*
         This updates the user, auth & cart.
         It also updates third party concerns ie. Kustomer, Bugsnag, Segment
      */
      await updateContingencies(user, {auth, cart});

      const userPartner = user?.account?.subscription?.partner;
      /*
        1. Does our user have a subscription with a partner?
        2. If so, lets set the partner in the queryClient & in _ht_branding cookie
      */
      if (userPartner) {
        setUserPlanBrandingAndCookies(user);
      }
    })();
  }, []);

  useEffect(() => {
    if (process.browser) {
      // @ts-ignore
      window.toggleDevTools = () => setShowDevTools(show => !show);
    }
  }, []);

  /* Get at Segment cookie */
  useEffect(() => {
    window?.analytics?.ready(() => {
      setShouldRun(true);
    });

    return () => {
      router.events.off('routeChangeStart', handleRouteStart);
      router.events.off('routeChangeComplete', NProgress.done);
    };
  }, []);

  /* Track the page views on page change */
  useEffect(() => {
    if (router.isReady) {
      /* route.on does not fire on first load. Capture global concerns here */
      GlobalSettingsLoad(router, {firstLoad: true});
    }
  }, [router.isReady]);

  return (
    <>
      <DefaultSeo {...SEO} />
      <ErrorBoundary FallbackComponent={attrs => <ErrorComponent {...attrs} />}>
        <SplitFactoryProvider config={index} updateOnSdkUpdate={true} updateOnSdkTimedout={false}>
          <QueryClientProvider client={queryClient}>
            <HydrationBoundary state={pageProps.dehydratedState}>
              <div className="appContainer">{Component.Layout ? Component.Layout(<Component {...pageProps} />) : <Component {...pageProps} />}</div>
              {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}

              {showDevTools ? (
                <React.Suspense fallback={null}>
                  <ReactQueryDevToolsProduction />
                </React.Suspense>
              ) : null}
              {/* App wide modals */}
              <LoginModalManager showLogo showCloseIcon />
              <LoginPasscodeModal />
              <ClaimStoreCreditsModal />
              {/* App Level behaviors ie, tracking, notifications etc... */}
              <AppLevelSideEffects pageProps={pageProps} />
            </HydrationBoundary>
          </QueryClientProvider>
        </SplitFactoryProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
