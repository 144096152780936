import {IBadgeProps} from 'ht-styleguide/build/Badge/Badge.types';

/*
 * Used for alerts/modals etc..
 *
 * USED: Snackbars
 * */

export enum STATUSES {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}

export enum COMPONENT_POSITION {
  fixed = 'fixed',
  relative = 'relative',
}

/* Badge props, partial */
export type BadgeProps = Omit<IBadgeProps, 'children'> & {text: string};
