import {useState, useCallback, useEffect} from 'react';
import {noop} from 'src/utils/event';
import {Logger} from 'src/utils/logger';
import {RESEND_COOLDOWN_MS} from '../constants';

/* Integrations */
import {SPLITIONAME_PASSWORDLESS_CHECKOUT, SplitTreatmentValuesEnum} from '@integrations/split/split.constants';
import useSplit from '@integrations/split/useSplit';

/* Apis */
import {user} from '@api/apis';

/* Client Store */
import {MODAL_LOGIN_PASSCODE, useGetModalDataByName, useModalStoreActions} from '@store/client/store.modals';

/* Mutations */
import useUserMutationAuthenticatePasscode from '@store/queries/user/useUserMutation.AuthenticatePasscode';

/**
 * Extracts the number of seconds from the error message if it contains a specific format.
 *
 * @param {string} errorMessage - The error message to extract seconds from.
 * @return {number|null} The number of seconds extracted from the error message, or null if not found.
 */
const parseCooldownTimeFromError = (errorMessage: string) => {
  const match = errorMessage?.match(/Please wait (\d+) seconds/);
  return match ? parseInt(match[1], 10) * 1000 : null;
};

/**
 * Manages the cooldown timer for passwordless login.
 *
 * @param {number} defaultCooldownTime - The default countdown time in milliseconds. Defaults to RESEND_COOLDOWN_MS.
 */
export const useCountdownTimer = (defaultCooldownTime = RESEND_COOLDOWN_MS) => {
  /* Hooks */
  const [remainingTime, setRemainingTime] = useState(0);
  const modalData = useGetModalDataByName(MODAL_LOGIN_PASSCODE);

  /* Constants */
  const loginPasscodeError = modalData?.error;
  const cooldownTimeFromError = parseCooldownTimeFromError(loginPasscodeError as string);
  const currentCooldownTime = cooldownTimeFromError || defaultCooldownTime;

  /* Methods */
  const startTimer = useCallback(
    ({startFromBeginning = false} = {}) => {
      const time = startFromBeginning ? defaultCooldownTime : currentCooldownTime;
      setRemainingTime(time);
    },
    [currentCooldownTime, defaultCooldownTime],
  );

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (remainingTime > 0) {
      timerId = setInterval(() => {
        setRemainingTime(prevTime => prevTime - 1000);
      }, 1000);
    }

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [remainingTime]);

  const canResend = remainingTime === 0;

  return {
    /** The current remaining time in milliseconds. */
    remainingTime,
    /** A function to start the countdown timer. */
    startTimer,
    /** A boolean indicating whether resending is allowed (true when remainingTime is 0). */
    canResend,
  };
};

/** Handles sending auth codes for passwordless login. */
export const useSendAuthCode = () => {
  /* Hooks */
  const {updateErrorIntoModal} = useModalStoreActions();

  /* Methods */
  const updateModalError = (error = '') => {
    updateErrorIntoModal({name: MODAL_LOGIN_PASSCODE, error});
  };

  const sendAuthCode = async ({email = '', onSuccess = noop, onError = noop}) => {
    updateModalError();
    // api.toggleLoader(true);

    try {
      const response = await user.sendAuthCode({email});

      if (response.data?.err || response.data?.errors) {
        // Use the error message from the server if available
        const errorMessage = response.data?.errors?.[0] || response.data?.err || 'An error occurred. Please try again.';
        if (errorMessage) {
          updateModalError(errorMessage);
        }
      }
      onSuccess();
    } catch (error: any) {
      updateModalError(error?.message);
      Logger('sendAuthCode')(error);
      onError(error);
    } finally {
      // api.toggleLoader(false);
    }
  };

  return sendAuthCode;
};

/**
 * Manages passcode auth for passwordless login.
 *
 * This hook handles both verifying and resending passcodes. Note that verifying
 * the code is done in the VALIDATE_AUTH_CODE saga which handles its own success/error, so
 * no success/error handlers are needed for verification.
 *
 * @param {Object} options - The options for passcode authentication.
 * @param {Function} [options.onResendSuccess] - Callback function to be called on successful passcode resend.
 * @param {Function} [options.onResendError] - Callback function to be called on passcode resend error.
 */
export const usePasscodeAuth = ({onResendSuccess = noop, onResendError = noop}) => {
  const modalData = useGetModalDataByName(MODAL_LOGIN_PASSCODE);
  const {error, data = {}} = modalData || {};

  // Mutations
  const {mutate, isPending} = useUserMutationAuthenticatePasscode();

  /* Constants */
  const email = data?.email;
  const userInitials = data?.userInitials;
  const phoneLastFour = data?.phoneLastFour;
  const loginPasscodeError = error;

  // The Passcode modal can be opened in normal login flow or during checkout.
  // If it's in the checkout flow, we need to pass the isPasswordlessCheckout flag
  // for downstream effects.
  const isPasswordlessCheckout = false; // for now;

  const sendAuthCode = useSendAuthCode();

  const verifyPasscode = async (code: string) => {
    mutate({code, email, isPasswordlessCheckout});
  };

  const resendPasscode = async () => {
    sendAuthCode({email, onSuccess: onResendSuccess, onError: onResendError});
  };

  return {
    /** Function to verify the passcode. */
    verifyPasscode,
    /** Function to resend the passcode. */
    resendPasscode,
    /** The email address associated with the authentication. */
    email,
    /** The initials of the user. */
    userInitials,
    /** The last four digits of the user's phone number. */
    phoneLastFour,
    /** Any error message related to passcode login */
    loginPasscodeError,
    /** Is our mutation in process/processing? */
    isPending,
  };
};

/**
 * Handles the split for showing passcoode and passwordless login.
 */
export const useIsPasswordlessCheckoutEnabled = () => {
  const {treatment} = useSplit(SPLITIONAME_PASSWORDLESS_CHECKOUT);

  return [SplitTreatmentValuesEnum.ON, SplitTreatmentValuesEnum.CONTROL, SplitTreatmentValuesEnum.SEED_CONTROL].includes(
    treatment as SplitTreatmentValuesEnum,
  );
};
