import {useWorkflowManager} from '@components/UI/Snackbar/Custom/ManageWorkflow/useWorkFlowNotfication';

/**
 * If we need Notifications on an app level that spans the whole feature site.
 */

const Notifications = () => {
  /* Hooks */
  useWorkflowManager();

  return null;
};

export default Notifications;
