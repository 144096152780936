import React from 'react';
import dynamic from 'next/dynamic';

import {MODAL_LOGIN_PASSWORDLESS, useGetModalDataByName} from '@store/client/store.modals';
import {useAppOpenSlideModalLogin} from '@store/client/store.app';

/* Dynamic */
const LoginModal = dynamic(() => import('@scenes/LoginPage/Modal/LoginModal'), {ssr: false});
const LoginModalPasswordless = dynamic(() => import('@scenes/LoginPage/Modal/LoginModal.Passwordless').then(mod => mod.LoginModalPasswordless), {
  ssr: false,
});

export const LoginModalManager = ({showLogo, showCloseIcon}: {showLogo: boolean; showCloseIcon: boolean}) => {
  const passwordlessModal = useGetModalDataByName(MODAL_LOGIN_PASSWORDLESS);
  /* Need to move this and passwordless to a "login" store instead */
  const normalLoginModal = useAppOpenSlideModalLogin();

  if (!normalLoginModal && !passwordlessModal) {
    return null;
  }

  if (passwordlessModal?.visible) {
    return <LoginModalPasswordless />;
  }

  if (normalLoginModal) {
    return <LoginModal showLogo showCloseIcon />;
  }

  return null;
};
