import React, { useCallback, useEffect } from 'react';
import { Modal, ELEMENT_SIZE } from 'ht-styleguide';

// Client Store
import { MODAL_LOGIN_PASSCODE, useGetModalDataByName, useModalStoreActions } from '@store/client/store.modals';

// Hooks
import { useCountdownTimer } from './LoginPasscode.hooks';

// Components
import { LoginPasscodeForm } from './LoginPasscode.Form';

// Styles
import styles from '../modal.module.scss';

export const LoginPasscodeModal = () => {
  /* Hooks */
  const {updateAppLevelModal} = useModalStoreActions();
  const modalData = useGetModalDataByName(MODAL_LOGIN_PASSCODE);
  const { startTimer, remainingTime, canResend } = useCountdownTimer();

  /* Constants */
  const isModalOpen = modalData?.visible;

  /* Methods */
  const onResendPasscodeSuccess = useCallback(() => {
    startTimer({ startFromBeginning: true });
  }, [startTimer]);

  useEffect(() => {
    if (isModalOpen) {
      startTimer();
    }
  }, [isModalOpen, startTimer]);

  if (!isModalOpen) return null;

  return (
    <Modal
      isVisible={isModalOpen}
      hide={() => updateAppLevelModal({ name: MODAL_LOGIN_PASSCODE, visible: false })}
      header="Confirm it's you"
      headerClasses={styles.passcodeModalHeaderContainer}
      elementSize={ELEMENT_SIZE.MEDIUM}
    >
      <LoginPasscodeForm
        onResendSuccess={onResendPasscodeSuccess}
        remainingTime={remainingTime}
        canResend={canResend}
      />
    </Modal>
  );
};
