import {createWithEqualityFn} from 'zustand/traditional';
import {immer} from 'zustand/middleware/immer';
/* ----------------------------------------------------------------------- */
/* --------------------- TYPES  ------------------------------------------ */
/* ----------------------------------------------------------------------- */
export type TCouponState = {
  coupons: {
    base: string;
  };

  actions: {
    setCoupons: (couponArgs: Record<string, string>) => void;
    clearCoupons: BaseAnyFunction;
  };
};

const initialCouponState = {
  base: '',
};

/* ----------------------------------------------------------------------- */
/* ---------------------  CHAT STORE SETUP  ------------------------------ */
/* ----------------------------------------------------------------------- */

const useCouponsStore = createWithEqualityFn<TCouponState>()(
  immer((set, get) => ({
    coupons: initialCouponState,

    /* Actions */
    actions: {
      setCoupons: couponArgs => {
        set(state => {
          state.coupons = {...state.coupons, ...couponArgs};
        });
      },
      clearCoupons: () => {
        set(state => {
          state.coupons = initialCouponState;
        });
      },
    },
  })),
);

/* Export Selectors */
export const useCoupons = () => useCouponsStore(state => state.coupons);
export const useCouponsActions = () => useCouponsStore(state => state.actions);

export default useCouponsStore;

/**
 * Usage
 * setCoupon({base: 'value'})
 *
 * If you want to clear it: clears all values
 * clearCoupons()
 * or
 * setCoupons({base: ''})
 *
 * Previously we had the concept of a coupon "param" from the url.
 * It shouldn't matter where the coupon comes from, it should be set in the same place.
 * If a coupon comes in from url, but then is set from a sku page? Just override it.
 *
 * Note: We allow for 'base' because we may have other coupon types in the future,
 *       ie. giftCard, though again - why not just use base?
 */
