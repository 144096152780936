import Cookies from 'js-cookie';
import get from 'lodash/get';

/**
 * This cookie is used for the direct booking feature.
 * If a client proceeds to book a service with a particular tech,
 * the tech's id and phone number is stored.
 *
 * data = {
 *   techId: number;
 *   phoneNumber: string;
 * }
 */

const DIRECT_BOOKING_TECH_ID = '_direct_booking_tech_id';
const EXPIRATION_DAYS = 1;

export const setDirectBookingTechData = ({data = {}}) => {
  Cookies.set(DIRECT_BOOKING_TECH_ID, JSON.stringify(data), {expires: EXPIRATION_DAYS});
};

const getDirectBookingData = () => {
  const data = Cookies.get(DIRECT_BOOKING_TECH_ID);
  return data ? JSON.parse(data) : {};
};

export const getDirectBookingTechId = () => {
  const data = getDirectBookingData();
  const techId = get(data, 'techId', null);
  return techId ? parseInt(techId, 10) : null;
};

export const getDirectBookingPhoneNumber = () => {
  const data = getDirectBookingData();
  return get(data, 'phoneNumber', null);
};

export const removeDirectBookingTechData = () => {
  Cookies.remove(DIRECT_BOOKING_TECH_ID);
};
