import {useEffect} from 'react';
import usePartnerCache from '@integrations/partners/usePartnerQuery.cache';
import {queryClient} from '@integrations/reactquery';
import {PartnerQueryKey} from '@integrations/partners/usePartnerQuery.set';
import {mergeWithPartnerObjects} from '@integrations/partners/partners.utils';
import {PartnerBaseAttributes} from '@integrations/partners/partners.types';

const Partner = ({pageProps}: {pageProps: any}) => {
  const currentPartner = usePartnerCache() as PartnerBaseAttributes;

  /* Partner Query: We want to run this on hydration of the pageProps and partner */
  useEffect(() => {
    if (pageProps?.partner) {
      /* Lets set the partner as soon as its available. */
      /* We need to take the current partner and merge with new partner */
      queryClient.setQueryData(PartnerQueryKey, mergeWithPartnerObjects(currentPartner, pageProps?.partner));
    }
  }, [pageProps?.partner]);

  return null;
};

export default Partner;
