import { HT_INFO_META, HT_INFO_SOCIAL } from '@constants/hellotech';

export default {
  viewport: 'width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no',
  title: HT_INFO_META.title,
  description: HT_INFO_META.description,
  titleTemplate: "%s | HelloTech",
  defaultTitle: HT_INFO_META.title,
  openGraph: {
    locale: 'en_US',
    site_name: HT_INFO_SOCIAL.facebook.og.siteName,
    type: HT_INFO_SOCIAL.facebook.og.type,
    title: 'HelloTech',
    description: '',
    url: HT_INFO_SOCIAL.facebook.og.url,
    updated_time: Math.floor(new Date().getTime() / 1000),
    images: [{
      url: HT_INFO_SOCIAL.facebook.og.image.url,
      width: HT_INFO_SOCIAL.facebook.og.image.width,
      height: HT_INFO_SOCIAL.facebook.og.image.height
    }]
  },
  twitter: {
    handle: '@hellotech',
    site: '@hellotech',
    cardType: 'summary',
    card: 'summary',
    title: 'HelloTech',
    creator: '@hellotech',
    description: '',
    image: HT_INFO_SOCIAL.facebook.og.image.url
  },
  facebook: {
    appId: HT_INFO_SOCIAL.facebook.appId,
    app_id: HT_INFO_SOCIAL.facebook.appId,
    admins: HT_INFO_SOCIAL.facebook.admins
  }
};


