/*
  Lets add the query keys here
  We should co-locate the queries/keys. But so far, the queries/mutations have been more global.
  Remember, co-late your queries/mutations.

  Why do we have these?
    Treat your keys like a dependency array. Also, when dealing with interaction methods like
    setQueriesData, invalidateQueries which will allow a "fuzzy" match. We can also "partial" match query keys too.
*/

/*
 * *********************************************
 * CART
 * ********************************************
 */
export const cartQueryKeys = {
  all: ['cart'] as const,
  canAddService: (id: number) => [...cartQueryKeys.all, 'canaddservice', id] as const,
  find: () => [...cartQueryKeys.all, 'find'] as const,
  itemPrice: <T>(item: T) => [...cartQueryKeys.all, 'itemprice', item] as const,
  skuPrice: (id: number) => [...cartQueryKeys.all, 'skuprice', id] as const,
  startBooking: () => [...cartQueryKeys.all, 'startbooking'] as const,
};
