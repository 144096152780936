
/* Utils */
import { getLeadGeneratedCookie } from '@utils/cookies/leadGeneration';
import segmentAnonymousId from '@utils/cookies/segmentAnonymousId';
import { isBrowser } from '@utils/environment';
import { queryClient } from '@integrations/reactquery';
import { userQueryKeys } from '@store/queries/user/queries.keys';

import { User } from 'types/user.types';

/**
 * This is an ongoing issue as customer.io doesn't behave quite like other third party vendors in relation to parsing segment data. It's also a learning process. As we can't know the shortfalls until we come across them, then adjust.
 * 1. This just re-arranges some code for more scaleability
 * 2. This adds a polling-esque feature so that we make associations between track events & lead generated person every minute or so. We do some quality of life items here: poll only when page is being viewed. When onunload etc.
 *
 * If the user is Logged In, we do not need to continually chain, however, it does not have a material impact if we do so.
 *    Customer.io will grab all events before and 5 minutes after the identify call and associate them.
 */
const leadGeneratedPolling = () => {
  /* Start polling for identify */
  /* Constants */
  const POLLING_INTERVAL = 60000 * 2; // 2 minutes

  /* Methods */
  /*
    The user might not be logged in, but if they have previously set user trait info
  */
  const existingUser = () => (isBrowser() && window.analytics?.user?.().traits?.()) || {};

  /**
   * Get the user from the queryClient
   */
  const isUser = () => {
    const user = queryClient.getQueryData(userQueryKeys.all) || {id: undefined};
    return (user as User).id;
  }

  /*
   shouldNotPoll
   We won't poll if:
     1. currently logged n
     2. analytics is not defined
     3. existingUser even when not logged in
     4. anonymousId is not set
     5. cookieLeadGeneratedAttrs is not set, thus user has not lead generated.

     Note:
  */
  const shouldPoll = (omitVisibilityState: boolean) => {
    const visibilityState = omitVisibilityState ? document.visibilityState : 'visible';

    if (!isBrowser()) return false;

    if (
      /* Make sure analytics is loaded */
      window.analytics &&
      /* Do we have an anonymous id */
      segmentAnonymousId() &&
      /* User is on the current tab */
      document.visibilityState === visibilityState &&
      /* User has entered a lead generated email */
      getLeadGeneratedCookie()?.email && !(
        /* User is logged in */
        !!isUser() ||
        /* User has previously logged in, thus not a lead */
        !!existingUser().id
      )
    ) {
      return true;
    }

    return false;
  }

  /**
   * fireIdentify
   * If we passed all the requirements of polling, fire the polling.
   * @returns {false|void}
   */
  const fireIdentify = (ovs = false) => shouldPoll(ovs) && window.analytics?.identify({ anonymous_id: segmentAnonymousId(), email: getLeadGeneratedCookie()?.email });

  /* Fire once as the user loads the page */
  fireIdentify();
  setInterval(() => {
    /*
     Lets try to associate track events to a users lead generated email.
     If the window is focused, poll, else don't. We don't want to just churn out identify if the user is not on the page (tabbed over)
    */
    fireIdentify();
  }, POLLING_INTERVAL);


  /* Get the listener. If a person leaves lets fire an identify to catch any associations that happened before the next firing. */
  if (isBrowser()) {
    document.onvisibilitychange = () => {
      if (document.visibilityState === 'hidden') {
        fireIdentify(true);
      }
    };
  }
}

export default leadGeneratedPolling;
