import {PRODUCTION, STAGING, DEVELOPMENT} from '@constants/base';

/**
 * Lets us know if we are on the server (non-window)
 * @returns {boolean}
 */
export const isServerSide = () => typeof window === 'undefined';

/**
 * Lets us know if we are on the server (non-window)
 * @returns {boolean}
 */
export const isBrowser = () => typeof window !== 'undefined';

/**
 * Lets us know if we are in production env
 * @returns {boolean}
 */
export const isProduction = () => process.env.NEXT_PUBLIC_NODE_ENV === PRODUCTION;

/**
 * Lets us know if we are in staging env
 * @returns {boolean}
 */
export const isStaging = () => process.env.NEXT_PUBLIC_NODE_ENV === STAGING;

/**
 * Lets us know if we are in development env
 * @returns {boolean}
 */
export const isDevelopment = () => process.env.NEXT_PUBLIC_NODE_ENV === DEVELOPMENT;

/**
 * Returns a string of which environment we are in
 * @returns {string}
 */
export const getEnvironment = () => process.env.NEXT_PUBLIC_NODE_ENV || PRODUCTION;

/**
 * Because we are proxying things most of these links go back the current site.
 * So, if we detect a proxied sku, then we preload and link as how it should.
 * If its not a proxied sku, we just use a normal anchor to redirect outside.
 *
 * @param sku
 */
export const isProxiedSku = ({id}: {id: number}) => {
  const proxiedSkus = process.env.NEXT_PUBLIC_PROXY_SKUS?.split(',').map(Number) || [];

  return proxiedSkus.includes(id);
};

/**
 * We need to know if we are being proxied or not
 */
export const isNextJSStandAlone = () => {
  const host = window?.location.hostname;
  const nextAppHost = process.env.NEXT_PUBLIC_HOST_URL!;

  return nextAppHost.includes(host) || isDevelopment();
};
