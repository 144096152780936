import {NextRouter} from 'next/router';
import useMobileStore from '@store/client/store.mobile';
import useNavigationStore from '@store/client/store.navigation';

import {appPageTracking} from '@components/App/globalConcerns/app.tracking';

/*
 * ----- This will fire on every "pageload" ----
 *
 * cookie settings, global page level tracking.
 *
 * Note: This is ONLY for the "global" settings.
 * */

export const GlobalSettingsLoad = (router: NextRouter, options: Record<string, boolean>) => {
  if (process.browser) {
    /* Tracking: Any tracking that needs handling */
    appPageTracking(router, options);
  }
};

/**
 * This is the global settings for the app, when its leaving a page.
 * NOTE: This is NOT the same as an "onunload" event.
 *
 * Since we are outside a component, we can still use zustands store here, but just
 * getState or setState programmatically.
 *
 * @constructor
 */
export const GlobalSettingsUnLoad = () => {
  const updateToggleSidebar = useMobileStore.getState().actions.updateToggleSidebar;
  const resetAllMenus = useNavigationStore.getState().actions.resetAllMenus;

  resetAllMenus();
  updateToggleSidebar(false);
};
