/**
 * Sourced from BE. Determines the current step in the workflow
 * See workflow.status provided by the BE endpoint.
 */
export const WORKFLOW_STATUS = {
  SITE_VISIT: 'site_visit',
  INSTALLATION: 'installation',
  FEEDBACK: 'feedback',
  COMPLETED: 'completed',
};
