import {createWithEqualityFn} from 'zustand/traditional';
import {immer} from 'zustand/middleware/immer';

/* ----------------------------------------------------------------------- */
/* --------------------- CONSTANTS  -------------------------------------- */
/* ----------------------------------------------------------------------- */
export const MODAL_DISCOUNT_FIRST_DISCOUNT = 'firstDiscount';
export const MODAL_LOGIN_PASSWORDLESS = 'loginPasswordless';
export const MODAL_LOGIN_PASSCODE = 'loginPasscode';
export const MODAL_LOGIN = 'login';

/* ----------------------------------------------------------------------- */
/* ---------------------  TYPES  ----------------------------------------- */
/* ----------------------------------------------------------------------- */
export type Modals = {
  [key: string]: {visible: boolean; data?: any} & Record<string, unknown>;
};

export type TAppModal = {
  name: string;
  visible?: boolean;
  data?: any;
  error?: string;
};

type TypeUpdateErrorProp = Record<'name' | 'error', string>;
export type ModalState = {
  modals: Modals;

  actions: {
    updateAppLevelModal: (modal: TAppModal) => void;
    updateErrorIntoModal: ({name, error}: TypeUpdateErrorProp) => void;
    deleteAppLevelModal: (name: string) => void;
  };
};

/* ----------------------------------------------------------------------- */
/* ---------------------  MODALS STORE SETUP  ---------------------------- */
/* ----------------------------------------------------------------------- */

const useModalStore = createWithEqualityFn<ModalState>()(
  immer(set => ({
    modals: {
      [MODAL_DISCOUNT_FIRST_DISCOUNT]: {visible: false, data: null, error: ''},
      /* THESE SHOULD BE MOVED TO A "LOGIN" STATE - not modals */
      [MODAL_LOGIN_PASSWORDLESS]: {visible: false, data: null, error: ''},
      [MODAL_LOGIN_PASSCODE]: {visible: false, data: null, error: ''},
    },

    /* Actions */
    actions: {
      deleteAppLevelModal: (name: string) =>
        set(state => {
          delete state.modals[name];
        }),
      updateErrorIntoModal: ({name, error}: TypeUpdateErrorProp) =>
        set(state => {
          state.modals[name].error = error;
        }),
      updateAppLevelModal: modalProps =>
        set(state => {
          const {name, visible, data} = modalProps;
          const {modals} = state;
          let toggledModalValue = {name, visible, data};
          /* If no visible is passed or its a boolean */
          if (!visible || typeof visible === 'boolean') {
            toggledModalValue = {visible: visible || !state.modals[name]?.visible, ...(data && {data})};
          }

          state.modals = {
            ...modals,
            [name]: {
              ...(modals[name] || {}),
              ...(toggledModalValue as object),
            },
          };
        }),
    },
  })),
);

/* Export Selectors */
export const useModalStoreActions = () => useModalStore(state => state.actions);
export const useCloseModalByName = (name: string) => useModalStore(state => state.modals?.[name] ?? null);
export const useModals = () => useModalStore(state => state.modals);
export const useGetModalDataByName = (name: string) => useModalStore(state => state.modals?.[name] ?? null);

export default useModalStore;

/*
  Usage:
  updateAppLevelModal({ name: MODAL_DISCOUNT_FIRST_DISCOUNT, data: whatever, visible: true })

  or to just toggle updateAppLevelModal({ name: MODAL_DISCOUNT_FIRST_DISCOUNT })
* */
