import {SyntheticEvent} from 'react';

/* Base Methods on Event */
const eventedPropagationMethods = ['preventDefault', 'stopPropagation'];
/* Run thru possible propagated methods, and stop on them */
const preventEventPropagation = (e: SyntheticEvent) =>
  eventedPropagationMethods.forEach(evt => {
    if (e && e[evt]) e[evt]();
  });

/**
 * A no-opt really, most for a backup fn
 * @returns {any}
 */
export const noop = (error?: any): any => ({});

/**
 * Prevents event bubbling
 * @param {React.SyntheticEvent} event
 */
export const prevent = (event: SyntheticEvent) => preventEventPropagation(event);

/**
 * Prevents event bubbling but also calls a arg function
 *
 * @param {BaseAnyFunction} fn
 * @returns {(event: React.SyntheticEvent) => void}
 */
export const prevented = (fn: BaseAnyFunction) => (event: SyntheticEvent) => {
  preventEventPropagation(event);
  fn(event);
};

/**
 * This stops propagation, but also passes thru the event to another function.
 * Mostly used for forms and such
 *
 * @param {BaseAnyFunction} fn
 * @returns {(event: React.SyntheticEvent) => void}
 */
export const stopImmediatePropagation = (fn: BaseAnyFunction) => (event: SyntheticEvent) => {
  if (event.stopPropagation) event.stopPropagation();
  if (event.nativeEvent.stopImmediatePropagation) event.nativeEvent.stopImmediatePropagation();
  fn(event);
};

/**
 * This basically allows for a data flow by creating a fake event obj to a FN that expects one.
 *
 * @param value
 * @returns {{stopPropagation: () => any; preventDefault: () => any; target: {value: unknown}}}
 */
export const syntheticEventCreation = (value: unknown) => ({
  target: {value},
  preventDefault: noop,
  stopPropagation: noop,
});
