import Branding from '@components/AppLevelSideEffects/Branding';
import PhoneCoupon from '@components/AppLevelSideEffects/PhoneCoupon';
import Notifications from '@components/AppLevelSideEffects/Notifications';
import Partner from '@components/AppLevelSideEffects/Partner';

const AppLevelConcerns = ({pageProps}: {pageProps: any}) => (
  <>
    <Branding />
    <PhoneCoupon />
    <Notifications />
    <Partner pageProps={pageProps} />
  </>
);

export default AppLevelConcerns;
