// import Script from 'next/script';

// new: G-VT5FE382F8

const GAScript = () => (
  <>
    <script
      type="text/partytown"
      dangerouslySetInnerHTML={{
        __html: `
            window.dataLayer = window.dataLayer || [];
            window.gtag = function gtag(){window.dataLayer.push(arguments);}
            gtag('set', 'linker', {
               'domains': ['help.hellotech.com']
            });
            gtag('js', new Date());
 
            gtag('config', 'AW-94905633/tuMMCI-53oIBEM3mxcQD', { 
                page_path: window.location.pathname,
            });
        `,
      }}
    />
  </>
);

export default GAScript;
