import {
  basePartnerAttributes,
  PARTNER_GROUP_SUBSCRIPTION,
  PARTNER_PHONES,
  PARTNER_PHONES_SUBSCRIPTION,
} from '@integrations/partners/partners.constants';
import {PartnerClient} from '@integrations/partners/partners.types';
import {WALMART_PLAN_ID} from '@constants/plan';
import {DefaultPhone} from '@constants/hellotech';
/**
 *
 * Partner Object
 *
 * LIST OF CUSTOM PARTNERS
 *
 * Lets hope we are consistent in our layouts. We should really just have one partners header that allows for
 * toggling within it. If we use the normal nav, its not a partners.
 *
 * @type {{default: {showCartIcon: boolean, hideMembershipLink: boolean, planAssociatedPhoneNumber: string | null, hideNavLinks: boolean, partnerName: string, planAssociations: Array<string | number> | null, showFooter: boolean, landingSeoName: string, themeAssociations: Array<string | number> | null, showChat: boolean, logoPosition: string, phone: string, logo: string, htLogoLink: false, isCobranded: boolean}, walmart: {showCartIcon: boolean, hideMembershipLink: boolean, planAssociatedPhoneNumber: string, hideNavLinks: boolean, partnerName: string, planAssociations: (number | number)[], showFooter: boolean, landingSeoName: string, themeAssociations: Array<string | number> | null, showChat: boolean, logoPosition: string, phone: string, logo: string, htLogoLink: false, isCobranded: boolean}, xfinity: {showCartIcon: boolean, hideMembershipLink: boolean, planAssociatedPhoneNumber: string | null, hideNavLinks: boolean, partnerName: string, planAssociations: Array<string | number> | null, showFooter: boolean, landingSeoName: string, themeAssociations: Array<string | number> | null, showChat: boolean, logoPosition: string, phone: string, logo: string, htLogoLink: false, isCobranded: boolean}, target: {showCartIcon: boolean, hideMembershipLink: boolean, planAssociatedPhoneNumber: string | null, hideNavLinks: boolean, partnerName: string, planAssociations: Array<string | number> | null, showFooter: boolean, landingSeoName: string, themeAssociations: Array<string | number> | null, showChat: boolean, logoPosition: string, phone: string, logo: string, htLogoLink: false, isCobranded: boolean}}}
 * */

export const PARTNERS: Record<string, PartnerClient> = {
  walmart: {
    partnerName: 'walmart',
    logo: '/_nextassets_/images/partner/nav/logo_walmart.svg',
    phone: PARTNER_PHONES.walmart,
    landingSeoName: '',

    /* Base Attributes */
    ...basePartnerAttributes,
    /* base partner attributes overrides */
    logoPosition: 'left',
    showCartIcon: false,
    isCobranded: true,
    showFooter: false,
    hideMembershipLink: true,
    planAssociations: [6, WALMART_PLAN_ID],
    planAssociatedPhoneNumber: PARTNER_PHONES_SUBSCRIPTION.walmart,
    footerLegalLinks: false,
  },
  target: {
    partnerName: 'target',
    logo: '/_nextassets_/images/partner/nav/logo_target.svg',
    phone: DefaultPhone,
    landingSeoName: '',

    /* Base Attributes */
    ...basePartnerAttributes,
    /* base partner attributes overrides */
    logoPosition: 'right',
    showCartIcon: false,
    isCobranded: true,
    footerLegalLinks: false,
  },
  xfinity: {
    partnerName: 'xfinity',
    logo: '/_nextassets_/images/partner/nav/logo_xfinity.svg',
    phone: PARTNER_PHONES.xfinity,
    landingSeoName: 'xfinity',

    /* Base Attributes */
    ...basePartnerAttributes,
    /* base partner attributes overrides */
  },
  /**
   * Subscriptions
   * These are the partners that have a subscription with us. ie. Frontier
   * 1. They will have a different phone number, and a different logo.
   * 2. But they will be a normal D2C flow
   * 3. Generated from eithe a cookie "_ht_branding" or a user's subscription.partner
   *    Note: This is a legacy from client app
   *
   */
  [PARTNER_GROUP_SUBSCRIPTION]: {
    partnerName: '',
    logo: '',
    phone: '',
    landingSeoName: '',

    /* Base Attributes */
    ...basePartnerAttributes,
    /* base partner attributes overrides */
    logoPosition: 'right',
    showChat: true,
    htLogoLink: true,
    showFooter: true,
    hideMembershipLink: true,
    planAssociatedPhoneNumber: null,
    planAssociations: [33],
  },
  /*
      Identified as a partners, but not a unique as those listed above.
      We will merge this with the partner returned.
 */
  default: {
    partnerName: '',
    logo: '',
    phone: DefaultPhone,
    landingSeoName: '',

    /* Base Attributes */
    ...basePartnerAttributes,
    /* base partner attributes overrides */
    planAssociatedPhoneNumber: null,
    footerLegalLinks: true,
    footerSiteLinks: false,
  },
};

/**
 * Silly const for one-off landing/partners.
 */
export const LANDINGS_PAGE_PARNTERS_ONE_OFFS = {};
