/*
 * APP LEVEL STATE
 *
 * These are base state items that do not qualify for their own atomic state
 * ie. loaders, third party
 *
 * FEATURE STATE SHOULD EXIST IN THE FEATURE PROPER, NOT IN THE 'STORE' DIRECTLY. CO-LOCATE WITH YOUR FEATURE>
 * */
import {createWithEqualityFn} from 'zustand/traditional';
import {immer} from 'zustand/middleware/immer';
import {isMobile, isMobileOrTablet, isBrowser} from '@utils/ui';
import {sleep} from '@utils/helpers';

/* ----------------------------------------------------------------------- */
/* ------------------------------- TYPES  -------------------------------- */
/* ----------------------------------------------------------------------- */
export type ResponsiveState = {
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isMobileOrTablet: boolean;
  deviceType: string;
  initialized: boolean;

  actions: {
    updateWindowSize: () => void;
    setInitializeResponsive: () => void;
  }
};

export enum DeviceTypes {
  Mobile = 'Mobile',
  Tablet = 'Tablet',
  Desktop = 'Desktop',
}

/* ----------------------------------------------------------------------- */
/* ------------------------------- UTILS  -------------------------------- */
/* ----------------------------------------------------------------------- */
function getType(): DeviceTypes {
  return (() => {
    switch (true) {
      case isMobile():
        return DeviceTypes.Mobile;
      case isMobileOrTablet():
        return DeviceTypes.Tablet;
      default:
        return DeviceTypes.Desktop;
    }
  })();
}

/* ----------------------------------------------------------------------- */
/* ---------------------  RESPONSIVE STORE SETUP  ------------------------ */
/* ----------------------------------------------------------------------- */
const useResponsiveStore = createWithEqualityFn<ResponsiveState>()(
  immer((set, get) => ({
    isDesktop: false,
    isMobile: false,
    isTablet: false,
    isMobileOrTablet: true,
    deviceType: DeviceTypes.Mobile,
    initialized: false,

    /* Actions */
    actions: {
      updateWindowSize: () => {
        set(state => {
          const mobileOrTablet = isMobileOrTablet();
          state.isDesktop = !mobileOrTablet;
          state.isMobile = isMobile();
          state.isMobileOrTablet = mobileOrTablet;
          state.deviceType = getType();
        });
      },
      setInitializeResponsive: async () => {
        if (!get().initialized && isBrowser()) {
          window.addEventListener('resize', () => get().actions.updateWindowSize());
          await sleep(0);
          window.dispatchEvent(new Event('resize'));
        }

        set(state => {
          state.initialized = true;
        });
      }
    },
  })),
);

/* Export Selectors */
export const useResponsiveIsDesktop = () => useResponsiveStore(state => state.isDesktop);
export const useResponsiveIsMobile =  () => useResponsiveStore(state => state.isMobile);
export const useResponsiveIsTablet =  () => useResponsiveStore(state => state.isTablet);
export const useResponsiveIsMobileOrTablet =  () => useResponsiveStore(state => state.isMobileOrTablet);
export const useResponsiveDeviceType =  () => useResponsiveStore(state => state.deviceType);
export const useResponsiveActions =  () => useResponsiveStore(state => state.actions);

export default useResponsiveStore;
