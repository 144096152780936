import segmentAnonymousId from '@utils/cookies/segmentAnonymousId';
import SplitIO from '@splitsoftware/splitio/types/splitio';

export const getKeyAJSKeyForConfig = () => segmentAnonymousId() || 'anonymous';

export const index: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: process.env.NEXT_PUBLIC_SPLIT_KEY || 'defaultKey',
    trafficType: 'anonymous-user',
    key: getKeyAJSKeyForConfig(),
  },
  startup: {
    requestTimeoutBeforeReady: 1, // 1000 ms
    retriesOnFailureBeforeReady: 3, // 3 times
    readyTimeout: 3, // 3 sec
  },
  sync: {
    splitFilters: [{
      type: 'bySet',
      values: ['frontend']
    }],
    impressionsMode: 'NONE'
  },
};
