import Cookies from 'js-cookie';
import {PHONE_TRACKING_TYPES} from '@utils/cookies/phone';
import {EXPIRY_14_DAYS} from '@utils/cookies/cookies.constants';

/**
 * --------------------------------------------------------------------
 * PLAN SUBSCRIBER PHONE
 * --------------------------------------------------------------------
 * 14 day
 *
 *
 * @param {string} phoneNumber
 * --------------------------------------------------------------------
 */
const planSubscriberPhoneCookieName = PHONE_TRACKING_TYPES.SUBSCRIPTION_PHONE;
export const setPlanSubscriberPhone = (phoneNumber: string) => {
  Cookies.set(planSubscriberPhoneCookieName, phoneNumber, {expires: EXPIRY_14_DAYS});
};

export const removePlanSubscriberPhone = () => {
  Cookies.remove(planSubscriberPhoneCookieName);
};

export const getPlanSubscriberPhone = () => Cookies.get(planSubscriberPhoneCookieName);
