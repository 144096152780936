import {QueryClient, QueryClientProvider, HydrationBoundary, MutationCache, QueryCache} from '@tanstack/react-query';
import {Logger} from '@utils/logger';

/*
 * Log all errors thrown by react-query.
 * We should also add a toast/snackBar here.
 *
 * NOTE: this is a query only. Does not work with mutation
 * */
export function queryErrorHandler(error: unknown): void {
  let errorString: string;

  if (error instanceof Error) {
    errorString = error.toString().replace(/^Error:\s*/, '');
  } else {
    try {
      errorString = JSON.stringify(error);
    } catch (jsonError) {
      errorString = 'Unknown error';
    }
  }

  if (typeof errorString !== 'undefined') {
    // shows what type of error messages ie toast or ? We could pass in a meta attribute
  }
  /*
  Nice to decide on a global error toast on api errors.
  const id = 'react-query-api-error';
  toast.closeAll();
  toast({ id, title, status: 'error' });
 */

  Logger('queryErrorHandler')(errorString);
}

/* Instantiate the query client for the whole app */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      /* disable this globally. */
      staleTime: Infinity, // no timeframe, we need to be explicit in all other queries. User can't time out
      gcTime: Infinity, // no timeframe, we need to be explicit in all other queries. User can't time out
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      notifyOnChangeProps: 'all',
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: queryErrorHandler,
  }),
  mutationCache: new MutationCache({
    onError: queryErrorHandler,
  }),
});

const QUERY_BASE_TIMINGS = {
  staleTime: 600000, // 10 minutes before fresh data becomes stale
  cacheTime: 900000, // 15 minutes before cache is emptied
};

export {QueryClientProvider, queryClient, QueryClient, HydrationBoundary, QUERY_BASE_TIMINGS};
