import Script from 'next/script';

const AccessibeScript = () => (
  <Script strategy="afterInteractive" id="accessibe-script">
    {`
      (function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'left', leadColor : '#007b83', triggerColor : '#007b83', triggerRadius : '50%', triggerPositionX : 'left', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'medium', triggerOffsetX : 32, triggerOffsetY : 32, mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'center', triggerOffsetX : 16, triggerOffsetY : 0, triggerRadius : '50%' } }); }; h.appendChild(s); })();
    `}
  </Script>
);

export default AccessibeScript;
