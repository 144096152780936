import Cookies from 'js-cookie';

/* Constants/Utils  */
import {setBrandingCookie} from '@utils/cookies/branding';
import {setPlanSubscriberPhone} from '@scenes/LoginPage/login.cookies';
import {getPhoneObject} from '@utils/formatting';
import {brandingQueryKeys} from '@integrations/partners/branding/queries.keys';

/* Integrations */
import {PARTNERS} from '@integrations/partners/partners.groups';
import {PARTNER_GROUP_SUBSCRIPTION, PARTNER_PHONES_SUBSCRIPTION} from '@integrations/partners/partners.constants';
import {mergeWithPartnerObjects} from '@integrations/partners/partners.utils';
import {queryClient} from '@integrations/reactquery';

/* Client Store */
import useTrackingStore from '@store/client/store.tracking';

import {User} from 'types/user.types';

/**
 * On Pages Load (client side), we see if the user from session has a partner.
 * - in App.tsx
 * - in Login Mutation
 * @param {User} user
 */
export const setUserPlanBrandingAndCookies = (user: User) => {
  /* 1. Gather up needed attributes */
  const clientSubscriptionId = user.account?.subscription?.planId;
  // gets the partner name from the matching partner with associated plans
  const partnerKey = Object.keys(PARTNERS).find(key => PARTNERS[key].planAssociations?.includes(clientSubscriptionId as number));
  const partnerGroup = (partnerKey && PARTNERS[partnerKey]) || PARTNERS[PARTNER_GROUP_SUBSCRIPTION];
  const partner = user.account?.subscription?.partner;

  /* TS wants this guard */
  if (!partner) return;

  /* 2. What about a phone? */
  if (!partner?.phone) {
    partner!.phone = partnerGroup.phone || PARTNER_PHONES_SUBSCRIPTION[partner?.partnerName?.toLowerCase()]?.value;
  }
  /* 3. Partner match? Merge if so, if not. Merge with default subscription */
  const mergedPartner = mergeWithPartnerObjects(
    // @ts-ignore
    {...partner, ...(partner.phone && {planAssociatedPhoneNumber: getPhoneObject(partner.phone as string)})},
    partnerGroup,
  );
  /* 4. Update Brandng Query Object */
  queryClient.setQueryData(brandingQueryKeys.all, mergedPartner);

  /* 5. Set our cookies: _ht_branding, and subscriptionPhone */
  setBrandingCookie({...partner, ...(partner.phone && {planAssociatedPhoneNumber: partner.phone})});

  /* 6. Do we have a phone */
  if (partner!.phone && typeof partner!.phone !== 'string' && typeof partner!.phone !== 'number') {
    const ph = partner!.phone.value;
    // subscription cookie
    setPlanSubscriberPhone(ph);
    // add it to the client state
    useTrackingStore.getState().actions.setPhoneState(ph);
  } else if (partner?.phone) {
    const ph = partner.phone;
    // subscription cookie
    setPlanSubscriberPhone(ph as string);
    // add it to the client state
    useTrackingStore.getState().actions.setPhoneState(ph as string);
  }

  if (Cookies.get('new_user')) {
    Cookies.remove('new_user');
  }
};
