// @ts-nocheck

/* Utils */
import {noop} from '@utils/event';
import {isMobileOrTablet, checkIsBotUA} from '@utils/ui';

const shouldNotRunCheck = () => {
  const isNotBrowser = !process.browser;
  const isBotUA = checkIsBotUA();
  return isNotBrowser || isBotUA;
};

/*
 * Wrapper methods for Kustomer methods.
 * Enables us to error gracefully and format what we need.
 *
 * hideChatIconOverride: function that return a boolean
 * */
const HTKustomerChat = {
  start({callBack = noop, hideChatIconOverride} = {}) {
    window.Kustomer?.start(
      {
        hideChatIcon: hideChatIconOverride,
      },
      callBack,
    );
  },

  stop() {
    // kills current chat/conversation and hides chat box
    window.Kustomer?.stop();
  },

  identify(jwt) {
    if (shouldNotRunCheck()) {
      return;
    }

    // Auths the user and fetches their Kustomer data
    window.Kustomer?.login({
      jwtToken: jwt,
    });
  },

  open() {
    window.Kustomer?.open();
  },

  logout() {
    // Removes user info from the chat session
    window.Kustomer?.logout();
  },

  openAndSendMessage({message = '', assistantId = ''}) {
    if (shouldNotRunCheck()) {
      return;
    }
    if (message || assistantId) {
      // Calling start again to override the assistantId in previous calls to start
      this.open(() => {
        window.Kustomer?.createConversation({
          message,
          assistantId,
        });
      });
    }
  },

  hideChat() {
    // just hides chat box
    window.Kustomer?.close();
  },
};

const HTKustomerChatProxy = new Proxy(HTKustomerChat, {
  get(target, key, receiver) {
    if (shouldNotRunCheck()) return noop;

    return target[key];
  },
});

export default HTKustomerChatProxy;
