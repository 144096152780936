import { useEffect, useRef, useState } from 'react';

type CallbackFunction = () => boolean | string;

function useIntervalConditionally (callback: CallbackFunction, delay: number | null, maxIterations?: number | null, shouldRun: boolean = true)  {
  const savedCallback = useRef<CallbackFunction>(() => false); // Initialize with a function that returns a boolean
  const iterationCount = useRef(0);
  const [finishedObject, setFinished] = useState({ finished: false, callbackValue: ''});
  const [isRunning, setIsRunning] = useState(false); // New state variable to track if the interval is running

  const maxIterationsReached  = () => {
    if (maxIterations && iterationCount.current >= maxIterations) {
      return true;
    }

    return false;
  };

  /* Remember the callback. */
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  /*
   - Set up the interval.
   - Communicate with caller to determine if predicate fn passed every tick
      i. if so, clear interval
   - If number ticks exceed max, clear interval.
   */
  useEffect(() => {
    function tick(id: ReturnType<typeof setInterval>) {
      const done = savedCallback.current();

      if (done || maxIterationsReached() ) {
        setFinished({ finished: true, callbackValue: typeof done === 'boolean' ? done.toString() : done});
        clearInterval(id);
        setIsRunning(false); // Set isRunning to false when the interval is cleared
      }
      // eslint-disable-next-line no-plusplus
      iterationCount.current++;
    }

    if (delay !== null && shouldRun && !isRunning) { // Check if the interval is not already running
      setIsRunning(true); // Set isRunning to true when the interval starts
      const id: ReturnType<typeof setInterval> = setInterval(() => tick(id), delay);
      return () => {
        clearInterval(id);
      }
    }
    return () => {};

  }, [delay, shouldRun]);

  return { iterationCount, ...finishedObject }
}

export default useIntervalConditionally;
