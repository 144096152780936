import mergeWith from 'lodash/mergeWith';
import get from 'lodash/get';

/* Queries */
import usePartnerCache from '@integrations/partners/usePartnerQuery.cache';

/* Utils & Constants */
import {getBrandingThemeCookie} from '@integrations/partners/partners.cookies';
import {PARTNERS} from '@integrations/partners/partners.groups';
import {EXCLUDE_PARTNER_INTENTS, PARTNER_PHONES_SUBSCRIPTION} from '@integrations/partners/partners.constants';
import {getPhoneObject} from '@utils/formatting';

/* Types */
import {CoBrandedLogo} from 'types/sku.types';
import {
  FindCustomPartnerAndMergeOptions,
  IPartner,
  LandingPage,
  PartnerClient,
  PartnerAPI,
  PartnerBaseAttributes,
} from '@integrations/partners/partners.types';

/**
 * ************************************************************************************************
 * Get the partners logo
 *
 * @param {Partner} partner
 * @returns {{cobrandedLogo: string, staticLogo: string}}
 * ************************************************************************************************
 */
export const useGetPartnerImage = (partnerArg?: PartnerBaseAttributes) => {
  const partner = usePartnerCache() || partnerArg || null;
  const noResultsReturn = {logo: '', altText: ''};

  if (!partner) return noResultsReturn;
  /* Lets try to find image from Partner Object */
  const desktopCobrandImage = (partner as PartnerAPI)?.cobrandedLogo?.find((logo: CoBrandedLogo) => logo.resolution === 'desktop') ?? '';

  /* The partner might already have a logo attached. */
  const logo = (partner as PartnerClient).logo;
  /**
   * We need the partners name IN the landing cookie (landing response). Doing by id's creates an
   * issue across the environments.
   */
  if (!desktopCobrandImage) {
    const {landingSeoName} = partner as LandingPage;
    const partnerName = (partner as PartnerAPI)?.partnerName || '';
    const name = landingSeoName || partnerName;

    /* Lets see if we can find a match with our partners constants */
    const partnerImage = get(logo, 'original', logo);

    if (!partnerImage) return noResultsReturn;

    return {logo: partnerImage, altText: `${name} products & services`};
  }

  /*
   * We grab the co-brand logos, and use the default logo attached to the landingPage cookie.
   * But, we don't want to show a logo if the partner is a partner exception.
   * Meaning, we show the full nav. No room for a logo.
   * */
  return {
    logo: desktopCobrandImage?.original,
    altText: `${(partner as PartnerAPI)?.partnerName} products & services`,
  };
};

/**
 * ************************************************************************************************
 * Does our partners have a logo?
 *
 * @param {Partner} partner
 * @returns {boolean}
 * ************************************************************************************************
 */
export const canDerivePartnerLogo = (partner: PartnerBaseAttributes): boolean => Boolean(useGetPartnerImage(partner).logo);

/**
 * ************************************************************************************************
 * Lodash mergeWith Helper.
 * The goal here is to take the partner handed back from app and merge it with our
 * Group partners. In future this should all be coming back from the BE from an updated
 * tool.
 *
 * @param firstObj
 * @param secondObj
 * @returns {any}
 * ************************************************************************************************
 */
export const mergeWithPartnerObjects = (firstObj: PartnerBaseAttributes, secondObj: PartnerBaseAttributes) =>
  mergeWith(firstObj, secondObj, (objValue, srcValue) => {
    if (Array.isArray(srcValue) && srcValue.length === 0) {
      return objValue;
    }
    if (srcValue === null || srcValue === '') {
      return objValue;
    }

    if (!srcValue) {
      return objValue;
    }

    return objValue || srcValue;
  });

/**
 * ************************************************************************************************
 * If the page has a partner, we can grab the extended partner data (partners.groups.ts), and merge
 * that into the pageProps for partner.
 *
 * TODO: We can possibly offload the passing to the query here.
 * @param {Partner} partner
 * @param options
 * ************************************************************************************************
 */
// Silly workaround to get a partner that is not really a partner.
const excludePartnerIntents = (partnerName: string = '') => {
  const name = partnerName?.toLowerCase();

  return EXCLUDE_PARTNER_INTENTS.includes(name);
};
export const findCustomPartnerAndMerge = (partner?: PartnerAPI, options?: FindCustomPartnerAndMergeOptions) => {
  if (!partner || excludePartnerIntents(partner.partnerName)) return null;
  /* get partner name */
  const name = partner?.partnerName?.toLowerCase() || '';

  /*
    1. Does our partner have a match in the custom list of Partners?

     Try to find a match within the custom list of Partners
     It's possible that the partner names are unique but are actually a base partner.

  ie.. "Comcast Xfinity" for our custom "xfinity". We could have another key of "associatedPartner".
        for now, this can work.
 */
  const matchedCustomPartner =
    Object.keys(PARTNERS).find(key => {
      const k = key?.toLowerCase();

      return name.includes(k);
    }) || 'default';

  const foundPartner = PARTNERS[matchedCustomPartner];
  /*
     2. Do we have a co-branded view? (not sure this is needed).

     I don't know why we have a co-branded view. Silly. but lets see if we returned cobranded images,
     and to see if our custom partner wants a cobranded view.
  */
  const isCobranded = [foundPartner, partner].some(p => p && 'cobrandedLogo' in p && p.cobrandedLogo?.length > 0);

  /*
    3. Do we have a logo for the partner? If so, lets merge it in to coBrandedLogo (if we don't have)
  */
  const partners = [foundPartner, partner];

  const partnerWithLogo = partners.find(p => p && p.logo);

  const coBrandedLogo = !isCobranded &&
    partnerWithLogo && {
      isCobranded: true,
      cobrandedLogo: [
        {
          mediaType: 'image',
          resolution: 'desktop',
          original: get(partnerWithLogo, 'logo.original', get(partnerWithLogo, 'logo')),
        },
      ],
    };

  /*
    4.
      If we have a partner, but no matched customer, let see if there is a subscription number
  */
  const partnerSubscriptionPhone = !partner.planAssociatedPhoneNumber && PARTNER_PHONES_SUBSCRIPTION[name];

  /*
     5. Return our default partner or with our default.
   */
  if (partner) {
    const foundPartnerCopy = JSON.parse(JSON.stringify(foundPartner)); // Create a deep copy of partner
    const M = mergeWithPartnerObjects(foundPartnerCopy, {
      ...partner,
      ...options?.mergedAttributes,
      isCobranded,
      ...(coBrandedLogo && {...coBrandedLogo}),
      ...(partner.phone && {phone: getPhoneObject(partner.phone as string)}),
      ...(partnerSubscriptionPhone && {planAssociatedPhoneNumber: partnerSubscriptionPhone}),
      ...(partnerSubscriptionPhone && {hideMembershipLink: true}),
      time: new Date().getTime(),
    });

    return M;
  }

  return null;
};

/**
 * ************************************************************************************************
 * If the cookie is not valid, we don't want to associate the partner with the passed partner.
 * @param cookie
 * @param partner
 * @returns {boolean}
 * ************************************************************************************************
 */
export const validPartnerAssociation = (cookie?: LandingPage, partner?: PartnerBaseAttributes): boolean => {
  if (!cookie || !cookie?.partnerId) return false;
  if (!partner) return false;

  const cookiePartnerId = cookie?.partnerId;
  const partnerId = partner?.id;

  if (cookiePartnerId && partnerId) {
    if (+cookiePartnerId === partnerId) return true;
  }

  return false;
};

/**
 * ************************************************************************************************
 * The issue with partners is there is no real formative way, as in the readme.me file, until we can
 * get some clarity, we will have to do this.
 *
 * Partner data can be on the sku, landing page cookie, or custom partner object (ie. target/walmart)
 *
 * @param {any} cookie
 * @param {any} partner
 * @returns {any}
 *
 * NOTE: NOT USED
 * ************************************************************************************************
 */
export const normalizeAndSetPartnerQuery = ({partner}: {partner: IPartner['partner']}) =>
  /* If we are coming from the client app, we need to deal with the cookie */
  // const cookieResponse = null;
  //
  // /* If one exists, but other doesn't */
  // if (!partner && cookieResponse) return cookieResponse;
  //
  // if (!cookieResponse && partner) return partner;
  //
  // if (!cookieResponse && !partner) return null;
  //
  // /* Is our partnerLanding the same partner as our passed partner? */
  // if (validPartnerAssociation(cookieResponse, partner)) {
  //   return mergeWithPartnerObjects(cookieResponse, partner);
  // }
  //
  // /* Another check is to match up by seoName We could still want walledGarden */
  // // if (cookieResponse?.landingSeoName === partner?.seoName) {...
  //
  // if (partner) return partner;
  // if (cookieResponse) return cookieResponse;
  //
  // // we could delete the cookie here?
  null;

/**
 *  ************************************************************************************************
 * If we need to find one, single level deep, key amonths the various Partner objects.
 *
 * @param {Array<T>} partnerObjsArray
 * @param {string} key
 * @returns {T[string] | undefined | null}
 * ************************************************************************************************
 */
export const findKeyInPartnerObjects = <T extends IPartner['partner']>(partnerObjsArray: Array<T>, key: string) => {
  if (!Array.isArray(partnerObjsArray)) return null;

  /* Single level deep only */
  const foundMatch = partnerObjsArray.find((partner: T) => partner?.[key]);

  return foundMatch?.[key];
};

/**
 * ************************************************************************************************
 * getUserBranding
 *
 * Plan Branding
 * This allows us to fold into the user server response the idea of a users branding preference.
 * Usually this is a partner, but this serves in a way as the user experience. So, its not a partner per se'.
 *
 * I am debating on adding this to the Partner key instead, what say you? "user partner type"?
 *
 * @param {User} user
 * @param {string} byType
 * @returns {{partner: Partner | null}
 * ************************************************************************************************
 */

const defaultUserPlanBrand = {partner: null, isPlanBranded: false};

/**
 * ************************************************************************************************
 *
 * @returns {{partner: null, isPlanBranded: boolean} | {partner: {partnerName: string, landingSeoName: string, logo: string, phone: string} & PartnerBaseAttributes, isPlanBranded: boolean}}
 *
 * ************************************************************************************************
 */
export const getCookiePlanBranding = () => {
  const planBrandingCookie = getBrandingThemeCookie();
  if (planBrandingCookie?.theme) {
    /* Find the partner association */
    const partnerKey = Object.keys(PARTNERS).find(key => PARTNERS[key].partnerName.toLowerCase() === planBrandingCookie.theme.toLowerCase());
    const partner = partnerKey && PARTNERS[partnerKey];

    if (!partner) return defaultUserPlanBrand;

    return {partner, isPlanBranded: true};
  }

  return defaultUserPlanBrand;
};
