/*
    These are HelloTech specific constants.
    Breaking this out into separate chunks of data. No reason to use the whole object for one email ref.
  */

/* ------------------------------------------------- */
/* ---------------- UTIL CONSTANTS ----------------- */
/* ------------------------------------------------- */
export const HT_INFO_UTILS = {
  cdn: '//cdn.hellotech.com',
};

/* ------------------------------------------------- */
/* ---------------- SITELINKS ---------------------- */
/* ------------------------------------------------- */
export const HT_INFO_SITELINKS = {
  blog: {
    url: 'http://www.hellotech.com/blog/',
  },
  mainSite: {
    url: 'https://www.hellotech.com',
  },
  facebook: {
    url: 'https://www.facebook.com/hellotech',
  },
  twitter: {
    url: 'https://twitter.com/hellotech',
  },
  linkedin: {
    url: 'https://www.linkedin.com/company/hello-tech/about/',
  },
  articles: {
    usToday: 'http://www.usatoday.com/story/tech/columnist/saltzman/2017/01/21/tech-support-we-all-need-but-how-do-you-get/96783928/',
    fortune: 'http://fortune.com/2017/05/30/succeeding-with-a-bolt-on-geek-squad/',
    verge: 'https://www.theverge.com/2017/8/23/16187814/samsung-galaxy-note-8-tech-support-hellotech',
  },
  techs: {
    url: 'https://content.hellotech.com/techs',
  },
  guide: {
    url: 'https://www.hellotech.com/guide',
  },
  careers: {
    url: 'https://www.fountain.com/c/hellotech/corporate-roles/US',
  },
  priceGuarantee: {
    url: 'https://promo.hellotech.com/price-match/',
  },
  partnerships: {
    url: 'https://content.hellotech.com/partnerships',
  },
};

/* ------------------------------------------------- */
/* ---------------- EMAILS ------------------------- */
/* ------------------------------------------------- */
export const HT_INFO_EMAILS = {
  admin: {
    john: 'john@hellotech.com',
  },
  info: {
    link: 'mailto:info@hellotech.com',
    title: 'info@hellotech.com',
  },
  general: {
    support: 'support@hellotech.com',
    onboarding: 'onboarding@hellotech.com',
  },
  office_support: {
    address: 'csus@hellotech.com',
  },
  support: {
    link: 'mailto:support@hellotech.com',
    title: 'support@hellotech.com',
  },
  techs: {
    appIssues: {
      link: 'mailto:app@hellotech.com',
      title: 'app@hellotech.com',
    },
  },
  unsubscribe: {
    link: 'mailto:unsubscribe@hellotech.com',
    title: 'unsubscribe@hellotech.com',
  },
};

/* ------------------------------------------------- */
/* ---------------- PHONE  ------------------------- */
/* ------------------------------------------------- */

export const DefaultPhone = {
  link: '',
  title: '',
  value: '',
};

export const HT_INFO_PHONE = {
  customers: {
    default: {
      link: 'tel:18449864945',
      title: '1-844-986-4945',
      value: '18449864945',
    },
    empty: {
      link: 'tel:10000000000',
      title: '1-000-000-0000',
      value: '10000000000',
    },
    plans: {
      link: 'tel:18007541007',
      title: '1-800-754-1007',
      value: '18007541007',
    },
    newPlans: {
      link: 'tel:18008953687',
      title: '1-800-895-3687',
      value: '18008953687',
    },
    subscriptionHolder: {
      default: {
        link: 'tel:18007538408',
        title: '1-800-753-8408',
        value: '18007538408',
      },
    },
    topNavExperiment: {
      link: 'tel:18332887680',
      title: '1-833-288-7680',
      value: '18332887680',
    },
    alternativeOne: {
      link: 'tel:18335995711',
      title: '1-833-599-5711',
      value: '18335995711',
    },
    alternativeTwo: {
      link: 'tel:18338923592',
      title: '1-833-892-3592',
      value: '18338923592',
    },
    zipcodeSkuGateSplit: {
      link: 'tel:18336472456',
      title: '1-833-647-2456',
      value: '18336472456 ',
    },
  },
  techs: {
    escalation: {
      link: 'tel:14242094660',
      title: '1-424-209-4660',
      value: '14242094660',
    },
  },
};

/* ------------------------------------------------- */
/* ---------------- SOCIAL MEDIA ------------------- */
/* ------------------------------------------------- */
export const HT_INFO_SOCIAL = {
  google: {
    review: 'https://www.google.com/search?q=HelloTech&ludocid=10347118932497971632#lrd=0x80c2bab91ee8f49d:0x8f9859d90442b9b0,3,5',
  },
  bestcompany: {
    review: 'https://bestcompany.com/tech-support/company/hellotech/leave-a-review',
  },
  facebook: {
    appId: '780036812045104',
    admins: ['100002397193848', '100000700373533', '675161394'],
    og: {
      siteName: 'HelloTech',
      title: 'HelloTech',
      type: 'website',
      url: 'https://www.hellotech.com',
      image: {
        url: 'https://cdn.hellotech.com/HT-sharing-logo.png',
        width: 300,
        height: 250,
      },
    },
  },
};

export const HT_INFO_META = {
  title: 'HelloTech: Home & Business Tech Support, Smart Home Set Up, Installations, and IT Services Near You',
  description:
    'HelloTech provides affordable, same-day on-site and 24/7 online tech support services such as computer repair, TV mounting, smart home installations, virus removal and more.',
};

export const HT_INFO_VIDEO = {
  youtubePromo: 'https://www.youtube.com/embed/7-aswjALzyU?rel=0&amp;showinfo=0',
};
