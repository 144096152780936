import {string, ref, object} from 'yup';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {DefaultValues, Mode} from 'react-hook-form/dist/types/form';

/**
 * --------------------------------------------------------
 * Validations & Errors associated with validations/forms
 * --------------------------------------------------------
 */
export const ValidationError = {
  firstName: 'First name is required',
  lastName: 'Last name is required',
  email: 'Email is required',
  email2: 'Please enter a valid email address',
  password: 'Password is required',
  passwordMin6: 'Password is too short - should be 6 chars minimum.',
  passwordConfirmation: 'Passwords must match',
  hearAboutUs: 'Please tell us how you heard about us',
  address: 'Address is required',
  phone: 'Phone is required',
  phoneLength: 'Phone requires 10 digits',
  zipCode: 'Enter 5-digit ZIP code',
  requiredText: 'Input is required',
  requiredNumber: 'Please enter a valid number',
  requiredCurrencyWithOptional: 'Please enter valid pricing',
  emptyRequirement: '',
  passcode: 'Passcode is required',
};

/**
 * --------------------------------------------------------
 * Validations Regexps
 * @type {RegExp}
 * --------------------------------------------------------
 */
const zipCode = /(^\d{5}$)/;
const phone = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
export const numberValidation = /(^\d+$)/;
// decimals and commas are optional
export const currencyWithOptionalsOnSubmit = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
export const currencyWithOptionals = currencyWithOptionalsOnSubmit;
export const anyCharacter = /\w+/;
/**
 * --------------------------------------------------------
 * Yup Schema
 * Merge validations errors with schema types
 * --------------------------------------------------------
 */
const YupValidations = {
  firstName: string().required(ValidationError.firstName).trim(),
  lastName: string().required(ValidationError.lastName),
  email: string().email(ValidationError.email2).required(ValidationError.email),
  email_autoOff: string().email(ValidationError.email2).required(ValidationError.email),
  password: Yup.string().required(ValidationError.password).min(6, ValidationError.passwordMin6),
  passwordConfirmation: string().oneOf([ref('password'), undefined], ValidationError.passwordConfirmation),
  hearAboutUs: string().required(ValidationError.hearAboutUs),
  address: string().required(ValidationError.address),
  phone: string().required(ValidationError.phone).matches(new RegExp(phone), ValidationError.phoneLength),
  zipCode: string().matches(zipCode, ValidationError.zipCode).max(5).required(ValidationError.zipCode),
  text: string().required(ValidationError.requiredText),
  numeric: string().matches(numberValidation, ValidationError.requiredNumber).required(ValidationError.requiredNumber),
  currency: string()
    .matches(currencyWithOptionals, ValidationError.requiredCurrencyWithOptional)
    .required(ValidationError.requiredCurrencyWithOptional),
  emptyRequirement: string(),
  addressDetails: string(),
  passcode: string().matches(numberValidation, ValidationError.passcode).max(6).required(ValidationError.passcode),
};

/**
 * YupSchemaResolver
 *
 * This serves as a helper. It is NOT a standalone to capture all the nuances of the
 * react-hook-form, else why have a wrapper hook ON a hook at all, redundant?
 *
 * This covers 99% of cases. IF you need additional items, just use it
 * in your single implementation, rather than carry that burden to all usages.
 *
 * For example: If you also want to use mode:
 *
 * useForm({
 *   ...YupSchemaResolver(['email'], undefined, { email: ''}),
 *   mode: 'onChange'
 * });
 */

export type YupSchemaResolverType = keyof typeof YupValidations | false;
export const YupSchemaResolver = (
  validations: YupSchemaResolverType[],
  reValidateMode: Exclude<Mode, 'onTouched' | 'all'> = 'onSubmit',
  defaultValues?: DefaultValues<any>,
) => {
  const filterOutNotValid = (key: YupSchemaResolverType) => key && YupValidations[key];
  const schemas = ([] as YupSchemaResolverType[])
    .concat(validations)
    .filter(filterOutNotValid)
    .reduce((schema, key) => {
      /*
       * We allow for name/validation key differences but you need to have
       * The valid name first, then "underscores(2)" followed by whatever you want
       * We split on the __ and take the first item as the key
       * */
      if (key) schema[key] = YupValidations[key];

      return schema;
    }, {});

  return {
    resolver: yupResolver(object().shape(schemas)),
    defaultValues,
    reValidateMode,
  };
};
