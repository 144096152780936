import {toQueryString, postJson, putJson, deleteJson, getJson} from './request';

export enum ApiHostType {
  DEFAULT = 'api',
  API2 = 'api2',
}

type Options = {
  replacements?: Record<string, string>;
  params?: string;
  apiHostType?: ApiHostType;
};

const baseUrls: Record<ApiHostType, string> = {
  [ApiHostType.DEFAULT]: process.env.NEXT_PUBLIC_API_HOST_FULL_URL || '',
  [ApiHostType.API2]: process.env.NEXT_PUBLIC_API2_HOST_FULL_URL || '',
};

/**
 * Constructs a full URL path with query parameters and dynamic path replacements.
 *
 * This function takes a path template (potentially with placeholders), replaces any placeholders
 * with actual values provided in the `replacements` object, and appends query parameters if provided.
 * It also selects the base URL based on the specified API host type.
 *
 * @param {string} path - The path template, possibly containing placeholders like ':id'.
 * @param {Options} options - Object containing replacements, query parameters, and API host type.
 * @returns {string} - The fully constructed URL path.
 */
function makePath(path: string, {replacements, params, apiHostType = ApiHostType.DEFAULT}: Options): string {
  let newPath = path;
  if (replacements) {
    Object.entries(replacements).forEach(([name, value]) => {
      newPath = newPath.replace(`:${name}`, value);
    });
    if (newPath.includes(':')) {
      throw new Error(`Not all replacements were provided: ${newPath}`);
    }
  }
  const arg = typeof params === 'string' ? JSON.parse(params) : params;
  const query = arg ? `?${toQueryString(arg)}` : '';
  return `${baseUrls[apiHostType]}${newPath}${query}`;
}

/**
 * Resolves arguments for dynamic path construction and API requests.
 *
 * This function processes the arguments provided for dynamic path construction, determining
 * whether path replacements are required and parsing the data and options for the request.
 *
 * @param {string} path - The path template that may contain placeholders.
 * @param {Array<string>} args - Arguments array that may include replacements, data, and options.
 * @returns {Object} - An object containing flags for replacements and the processed data and options.
 */
function resolvePathArgs(path: string, args: any[]): {shouldHaveReplacements: boolean; data: any; options: any; replacements: any} {
  const shouldHaveReplacements = path.includes(':');
  const replacements = shouldHaveReplacements ? args[0] : undefined;
  const data = shouldHaveReplacements ? args[1] : args[0];
  const options = args[shouldHaveReplacements ? 2 : 1] || {};
  return {shouldHaveReplacements, data, options, replacements};
}

/**
 * Creates a full GET request path with dynamic path replacements and query parameters.
 *
 * This function specifically handles the construction of a URL path for GET requests. It resolves
 * path arguments including dynamic path replacements and query parameters.
 *
 * @param {string} path - The path template for the GET request.
 * @param {ApiHostType} apiHostType - The type of API host for which the URL is being constructed.
 * @param {Array<string>} args - Arguments for constructing the path, including any dynamic replacements.
 * @returns {string} - The fully constructed URL path for the GET request.
 */
function makeGetPath(path: string, apiHostType: ApiHostType, args: any[]): string {
  const {replacements, data: params, shouldHaveReplacements} = resolvePathArgs(path, args);
  return makePath(path, {replacements: shouldHaveReplacements ? replacements : undefined, params, apiHostType});
}

/**
 * Builds a set of methods for making HTTP requests.
 *
 * This function returns an object with methods for performing HTTP GET, POST, PUT, and DELETE
 * requests. Each method is configured with the necessary URL, request options, and handles
 * the construction of the full request path.
 *
 * @returns {Object} - An object containing methods for GET, POST, PUT, and DELETE requests.
 */
export default function BuildMethods() {
  return {
    get(path: string, apiHostType: ApiHostType = ApiHostType.DEFAULT) {
      const curry = (...args: any[]) => {
        const {options} = resolvePathArgs(path, args);

        return getJson(makeGetPath(path, apiHostType, args), null, options);
      };
      curry.getPath = (args: any[]) => makeGetPath(path, apiHostType, args);
      return curry;
    },
    post(path: string, apiHostType: ApiHostType = ApiHostType.DEFAULT) {
      return (...args: any[]) => {
        const {replacements, data, options, shouldHaveReplacements} = resolvePathArgs(path, args);
        const result = makePath(path, {replacements: shouldHaveReplacements ? replacements : undefined, apiHostType});
        return postJson(result, data, options);
      };
    },
    put(path: string, apiHostType: ApiHostType = ApiHostType.DEFAULT) {
      return (...args: any[]) => {
        const {replacements, data, options, shouldHaveReplacements} = resolvePathArgs(path, args);
        const result = makePath(path, {replacements: shouldHaveReplacements ? replacements : undefined, apiHostType});
        return putJson(result, data, options);
      };
    },
    destroy(path: string, apiHostType: ApiHostType = ApiHostType.DEFAULT) {
      return (...args: any[]) => {
        const {replacements, data, options, shouldHaveReplacements} = resolvePathArgs(path, args);
        const result = makePath(path, {replacements: shouldHaveReplacements ? replacements : undefined, apiHostType});
        return deleteJson(result, data, options);
      };
    },
  };
}

export function getCookieValue(cookieString: string, cookieName: string): string | null {
  const match = cookieString.match(new RegExp(`(^|;\\s*)${cookieName}=([^;]*)`));
  return match ? decodeURIComponent(match[2]) : null;
}
