import React, {useCallback, useRef, useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import {Avatar, LoaderPage} from 'ht-styleguide';

// Hooks
import {usePasscodeAuth} from './LoginPasscode.hooks';

// Utils
import {formatInitialsForAvatar} from './LoginPasscode.utils';

// Constants
import {YupSchemaResolver} from '@constants/validations';

import {AUTO_SUBMIT_DEBOUNCE_TIMEOUT_MS, NUM_PASSCODE_DIGITS} from '../constants';
// Components
import {LoginPasscodeBoxes} from './LoginPasscode.Boxes';

// Styles
import styles from '../modal.module.scss';

const INSTRUCTIONS = [`Enter the ${NUM_PASSCODE_DIGITS}-digit code we just sent`, 'you to securely use your saved', 'information.'];

const PassCodeSpacer = () => <div className={styles.passcodeSpacer} />;

/**
 * Handles passcode input and verification for passwordless login.
 * Reusable across login pages, modals, and account verification flows.
 */
type LoginPasscodeFormProps = {
  onResendSuccess: BaseAnyFunction;
  className?: string;
  remainingTime: number;
  canResend?: boolean;
};

export const LoginPasscodeForm = ({onResendSuccess, className, remainingTime, canResend}: LoginPasscodeFormProps) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  /* Not happy with this. */
  const [isKey, setKey] = useState(1);

  /* Hooks */
  const {
    handleSubmit,
    formState: {errors},
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
  } = useForm<{passcode: number | string}>({
    ...YupSchemaResolver(['passcode'], undefined, {
      passcode: '',
    }),
  });

  const focusInputField = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const resetPasscodeAndFocusInput = useCallback(() => {
    setValue('passcode', '');
    setIsInputFocused(true);
    focusInputField();
  }, [setValue, focusInputField]);

  const {verifyPasscode, resendPasscode, email, phoneLastFour, userInitials, loginPasscodeError, isPending} = usePasscodeAuth({
    onResendSuccess,
    onResendError: resetPasscodeAndFocusInput,
  });

  const onSubmit = () => verifyPasscode(getValues('passcode') as string);
  const debouncedSubmit = useCallback(() => {
    debounce(
      handleSubmit(onSubmit, err => {}),
      AUTO_SUBMIT_DEBOUNCE_TIMEOUT_MS,
    )();
  }, [handleSubmit]);

  const handlePasscodeChange = (value: string) => {
    clearErrors();
    const enteredPasscode = value;
    setValue('passcode', enteredPasscode);

    if (enteredPasscode.length === NUM_PASSCODE_DIGITS) {
      debouncedSubmit();
    }
  };

  const handleResendClick = () => {
    reset();
    resendPasscode();
    resetPasscodeAndFocusInput();
  };

  useEffect(() => {
    focusInputField();
  }, [focusInputField]);

  useEffect(() => {
    if (loginPasscodeError) {
      setKey(prevKey => prevKey + 1);
      setError('passcode', {type: 'custom', message: loginPasscodeError as string});
      resetPasscodeAndFocusInput();
    }
  }, [loginPasscodeError, setError, resetPasscodeAndFocusInput]);

  return (
    <div className={className}>
      <PassCodeSpacer />
      {isPending && <LoaderPage />}

      <div className="text-align-center">
        <Avatar name={formatInitialsForAvatar(userInitials)} className={cn('marginBottom-small1', styles.avatar)} />
        <p className="p1 n900">{email}</p>
        <p className="p1 n900">*** *** {phoneLastFour}</p>
      </div>

      <PassCodeSpacer />

      <div className="positionRelative" key={isKey}>
        <div className={styles.passcodeBoxesContainer} onClick={focusInputField}>
          <input
            ref={inputRef}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            name="passcode"
            maxLength={NUM_PASSCODE_DIGITS}
            onChange={e => {
              const value = e.target.value.replace(/\D/g, '');
              handlePasscodeChange(value);
              setValue('passcode', value);
            }}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            className={styles.passcodeInput}
            autoComplete="one-time-code"
            /* eslint-disable-next-line jsx-a11y/no-autofocus */
            autoFocus
          />
          <LoginPasscodeBoxes
            passcode={getValues('passcode') as string}
            isInputFocused={isInputFocused}
            hasError={!!errors.passcode && (getValues('passcode') as string).length === NUM_PASSCODE_DIGITS}
            numBoxes={NUM_PASSCODE_DIGITS}
          />
        </div>

        {errors.passcode && <div className={styles.errorMessage}>{errors.passcode?.message || ''}</div>}
      </div>

      <PassCodeSpacer />

      <p className="p1 n900 text-align-center">
        {INSTRUCTIONS.map((instruction, index) => (
          <span key={index}>
            {instruction}
            <br />
          </span>
        ))}
      </p>

      <PassCodeSpacer />

      <div className="text-align-center">
        <p className="p1 n900">
          Don't see a code?&nbsp;
          {!canResend && <span>Give it {remainingTime / 1000}s</span>}
        </p>
        <br />
        <button type="button" className="plainButton" onClick={handleResendClick} disabled={!canResend}>
          <span
            className={cn('p1 underline', {
              n300: !canResend,
              teal: canResend,
            })}
          >
            Send Again
          </span>
        </button>
      </div>
    </div>
  );
};
