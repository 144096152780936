/**
 * Handle response error
 * Simple helper function to handle response errors
 * @param response
 * @param api
 */
type ApiResponse = {
  status: number;
  err?: string;
  error?: string;
};

export const handleResponseError = (response: ApiResponse) => {
  if (response.status >= 300) {
    const errorMessage = response.error || response.err || 'Unknown error occurred';
    throw new Error(JSON.stringify({message: errorMessage, status: response.status}));
  }
};
