import Cookies from 'js-cookie';

export const SEGMENT_ANONYMOUS_COOKIE_NAME = 'ajs_anonymous_id';

const segmentAnonymousId = () => {
  const segmentCookie = Cookies.get(SEGMENT_ANONYMOUS_COOKIE_NAME);
  if (segmentCookie) return segmentCookie.replace(/%22/g, '').replace(/"/g, '');
  return '';
};

export default segmentAnonymousId;
