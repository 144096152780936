/*
 *****************************************************
 * Plan Product Ids with the associated plans per id
 *****************************************************
 */

import {isProduction} from '@utils/environment';

// HTH Plan Product (1)
export const HELLOTECH_HOME_PLAN_PRODUCT_ID = 1;
export const UPSELL_PLAN_ID = 1;
export const MONTHLY_PLAN_ID = 6;
export const PRICING_TEST_YEARLY_ID = 7; // $199 yearly price - used for split test
export const PRICING_TEST_MONTHLY_ID = 8; // $19.99 monthly price - used for split test
export const UPSELL_PLAN_NAME = 'HelloTech Home';

// Walmart Plan Product (2)
export const WALMART_PLAN_PRODUCT_ID = 2;
export const WALMART_PLAN_ID = 4;

// HelloTech Lite Plan Product (3)
export const HELLOTECH_LITE_PLAN_PRODUCT_ID = 3;
export const HELLOTECH_LITE_PLAN = 9;
export const HELLOTECH_LITE_PLAN_ANNUAL_ID = (() => {
  if (isProduction()) {
    return 29;
  }
  return 32;
})();

// HelloTech Online Plan Product (4)
export const ONLINE_PLAN_PRODUCT_ID = 4;
export const ONLINE_PLAN_ID_MONTHLY = 11;
export const ONLINE_PLAN_ID = 12; // yearly
export const ONLINE_PLAN_IDS = [ONLINE_PLAN_ID, ONLINE_PLAN_ID_MONTHLY];

/*
 *****************************************
 * Other support constants
 ******************************************
 */

// The values here align with the plans/support_plans table
export const UPSELL_PLAN_BILLING_CYCLES = {
  ANNUAL: 'yearly',
  MONTHLY: 'monthly',
};

/*
 * Plan Pricing Types
 * Used to determine fixed or percentage
 * */
export const PLAN_PRICING_TYPE = {
  percentage: 'percentage',
  fixed: 'fixed',
};
