import Bugsnag from '@bugsnag/js';
import isString from 'lodash/isString';
import {isServerSide} from '@utils/environment';

/*
 * In cases where server side apis fail or bugsnag not loaded. Log to console.
 * */
type BugsnagErrorType = Error | string;
const logToConsole = (e: BugsnagErrorType) => console.log('Outside Bugsnag: ', e);
export const Logger =
  (source = '') =>
  (error: BugsnagErrorType) => {
    let err;

    switch (true) {
      case error instanceof Error:
        err = error;
        break;
      case isString(error):
        err = new Error(`${source}: ${error}`);
        break;
      default:
        err = new Error(`${source}: ${JSON.stringify(error)}`);
    }

    if (!isServerSide()) {
      Bugsnag?.notify(err);
    } else {
      logToConsole(err);
    }
  };
