import React from 'react';
import cn from 'classnames';
import styles from '@components/UI/Snackbar/Bar/bar.module.scss';

export const ManageWorkflowTemplate = ({templateData}: ManageWorkflowTemplateProps) => {
  const onLinkClick = templateData.onLinkClick;

  if (!onLinkClick) return null;
  const commonTextStyles = 'p1 successGreen500 text-weight-medium';
  return (
    <p className={styles.copy}>
      <span className={commonTextStyles}>Looking for your EV Installation project? </span>
      <button type="button" onClick={onLinkClick} className={cn(commonTextStyles, 'plainButton underline')}>
        Manage Project
      </button>
    </p>
  );
};

type ManageWorkflowTemplateProps = {
  templateData: {
    onLinkClick: BaseAnyFunction;
    get: BaseAnyFunction;
  };
};
