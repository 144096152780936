import {createWithEqualityFn} from 'zustand/traditional';
import {immer} from 'zustand/middleware/immer';
/* ----------------------------------------------------------------------- */
/* --------------------- CONSTANTS  -------------------------------------- */
/* ----------------------------------------------------------------------- */
export const APP_STATE_CATEGORY_MENU = 'app-state/sidebar/APP_STATE_CATEGORY_MENU';

/* ----------------------------------------------------------------------- */
/* --------------------- TYPES  ------------------------------------------ */
/* ----------------------------------------------------------------------- */
export type SidebarProps = {
  menuOnOpen?: string;
  newMenuOnOpenMobile?: string;
};

export type MobileState = {
  sidebarOpenMobile: boolean;
  newMenuOnOpenMobile: string;

  actions: {
    updateToggleSidebar: (value?: boolean) => void;
    updateToggleSidebarWithCategory: ({ menuOnOpen }: SidebarProps) => void;
  }
};

/* ----------------------------------------------------------------------- */
/* ---------------------  MOBILE STORE SETUP  ---------------------------- */
/* ----------------------------------------------------------------------- */

const useMobileStore = createWithEqualityFn<MobileState>()(
  immer(set => ({
    sidebarOpenMobile: false,
    newMenuOnOpenMobile: '',

    /* Actions */
    actions: {
      updateToggleSidebar: toggledValue =>
        set(state => {
          let newOpenState;
          if (typeof toggledValue === 'boolean') {
            newOpenState = toggledValue;
          } else {
            newOpenState = !state.sidebarOpenMobile;
          }

          return { sidebarOpenMobile: newOpenState, newMenuOnOpenMobile: '' };
        }),

      /* Toggle open sidebar to certain shop */
      updateToggleSidebarWithCategory: ({ menuOnOpen = APP_STATE_CATEGORY_MENU } = {}) =>
        set(state => {
          const newOpenState = !state.sidebarOpenMobile;
          const newMenuOnOpenMobile = newOpenState ? menuOnOpen : '';

          return { sidebarOpenMobile: newOpenState, newMenuOnOpenMobile };
        }),
    }
  })),
);

export const useMobileStoreActions = () => useMobileStore(state => state.actions);
export const useMobileSidebarOpenMobile = () => useMobileStore(state => state.sidebarOpenMobile);
export const useMobileNewMenuOnOpenMobile = () => useMobileStore(state => state.newMenuOnOpenMobile);

export default useMobileStore;
