import {useQuery, useQueryClient} from '@tanstack/react-query';
import {User} from 'types/user.types';

/* keys */
import {userQueryKeys} from './queries.keys';

/*
 * Read operation
 *
 * This returns the "cache" user
 * No api happens here, just a helper to read cache
 *
 * Async/Await not needed here.
 * */
const useUserCache = <T extends User = User>(select?: (data: User) => T): T => {
  const queryClient = useQueryClient();

  const {data} = useQuery({
    queryKey: userQueryKeys.all,

    queryFn: async () => {
      const user = await queryClient.getQueryData(userQueryKeys.all);

      return user as User;
    },
    select,
    // @ts-ignore
    initialData: {
      pristine: true,
    },
  });

  return data!;
};

export default useUserCache;
