/* ------------------------ TREATMENT VALUES ------------------------ */

/* Types */
export enum SplitTreatmentValuesEnum {
  ON = 'on',
  OFF = 'off',
  CONTROL = 'control',
  SEED_CONTROL = '_control_',
}

/* ------------------------ SPLITS -------------------------------- */

/* STEP 1: These are our specific references to the SPLIT KEYS (set in spit). They must match. */
export const SPLITIONAME_PROMO_BAR = 'promo_bar';
export const SPLITIONAME_NAV_PHONE_NUMBER = 'nav_phone_number';
export const SPLITIONAME_MONTHLY_PLAN_PRICING = 'monthly_plan_pricing';
export const SPLITIONAME_PLAN_PRICING = 'plan_pricing';
export const SPLITIONAME_CUSTOMER_ZIP = 'customer_zip_entry';
export const SPLITIONAME_PRODUCT_PAGE_MEMBERSHIP_UPSELL = 'product_page_membership_upsell';
export const SPLITIONAME_AFFIRM = 'affirm';
export const SPLITIONAME_PRODUCT_SPECIALIST_CHAT = 'product_specialist_chat';
export const SPLITIONAME_PRODUCT_SHOP_VERSION_TEST = 'product_shop_version_test';
export const SPLITIONAME_HOMEPAGE_IFRAME = 'homepage_iframe';
export const SPLITIONAME_NAVIGATION_V2 = 'navigation_v2';
export const SPLITIONAME_USER_EMAIL_VALIDATOR = 'user_email_validator';
export const SPLITNAME_DYNAMIC_HOMEPAGE_BANNER = 'dynamic_homepage_banner';
export const SPLITIONAME_PASSWORDLESS_CHECKOUT = 'passwordless_checkout';

/* ------------------------ SPLIT CONFIGS ------------------------ */
/* Config Types */
export type PromoConfig = {
  backgroundColor: string;
  textColor: string;
  icon: string;
  headline: string;
  ctaText: string;
  ctaLink: string;
};

/* STEP 1: If your split needs a config ie. colors/special text */
export const SPLITIO_SPLITS_WITH_CONFIG = {
  [SPLITIONAME_PROMO_BAR]: {
    name: SPLITIONAME_PROMO_BAR,
    config: {
      "id": true,
      "backgroundColor": "#133061",
      "textColor": "#ffffff",
      "icon": '',
      "headline": "Get in-home services tailored to your home.",
      "ctaText": "",
      "ctaLink": "/services",
      "ctaLinkExternal": "",
      "default": true,
    } as PromoConfig,
  },
  [SPLITIONAME_NAV_PHONE_NUMBER]: {
    name: SPLITIONAME_NAV_PHONE_NUMBER,
    config: {link: 'tel:18449864945', title: '1-844-986-4945', value: '18449864945'},
  },
  [SPLITIONAME_HOMEPAGE_IFRAME]: {
    name: SPLITIONAME_HOMEPAGE_IFRAME,
    config: {source: `homepage/1`},
  },
  [SPLITIONAME_PRODUCT_SHOP_VERSION_TEST]: {
    name: SPLITIONAME_PRODUCT_SHOP_VERSION_TEST,
    config: {path: ''},
    treatment: 'current',
  },
  [SPLITIONAME_NAVIGATION_V2]: {
    name: SPLITIONAME_NAVIGATION_V2,
    config: {path: 'v2'},
    treatment: SplitTreatmentValuesEnum.ON,
  },
};
