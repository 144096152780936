/*
  Lets add the query keys here
  We should co-locate the queries/keys. But so far, the queries/mutations have been more global.
  Remember, co-late your queries/mutations.

  Why do we have these?
    Treat your keys like a dependency array. Also, when dealing with interaction methods like
    setQueriesData, invalidateQueries which will allow a "fuzzy" match. We can also "partial" match query keys too.
*/

/*
 * *********************************************
 * User
 * ********************************************
 */
export const userQueryKeys = {
  all: ['user'] as const,
  auth: ['auth'] as const,
  current: () => [...userQueryKeys.all, 'current'] as const,
  custom: (keys: string[]) => [...userQueryKeys.all, ...keys] as const,
};
