import {useMutation} from '@tanstack/react-query';
import Cookies from 'js-cookie';
import {useRouter} from 'next/router';
import {htToast} from 'ht-styleguide';

/* Apis */
import {user} from '@api/apis';

/* Client Store */
import {MODAL_LOGIN_PASSCODE, useModalStoreActions} from '@store/client/store.modals';
import {updateContingencies} from '@store/queries/user/user.utils';

/* Constants */
import {accountPath} from '@constants/paths';

/* Utils */
import {Logger} from '@utils/logger';
import {handleResponseError} from '../utils/util.handleResponseError';
import {sleep} from '@utils/helpers';

/**
 * @param {IHash<any> | undefined} options
 * @returns {UseQueryResult<any, unknown>}
 */
type TAuthenticatePasscodeProps = {
  email: string;
  code: string;
  isPasswordlessCheckout: boolean;
};
export default function useUserMutationAuthenticatePasscode() {
  const {updateErrorIntoModal} = useModalStoreActions();
  const router = useRouter();

  return useMutation({
    mutationFn: async ({email, code, isPasswordlessCheckout}: TAuthenticatePasscodeProps) => {
      updateErrorIntoModal({name: MODAL_LOGIN_PASSCODE, error: ''});
      const response = await user.validateAuthCode({email, code});
      handleResponseError(response);

      if (response.data.err || response.data?.errors) {
        const errorMessage = response.data?.errors[0] || 'Something went wrong';
        updateErrorIntoModal({name: MODAL_LOGIN_PASSCODE, error: errorMessage});
        throw new Error(errorMessage);
      }

      return response.data;
    },
    onSuccess: data => {
      const {authToken} = data.user ?? {};

      if (Cookies.get('new_user')) {
        Cookies.remove('new_user');
      }

      const firstName = data?.user?.firstName;
      const welcomeBackMessage = firstName ? `Welcome back, ${firstName}!` : 'Welcome back!';
      htToast(welcomeBackMessage);

      if (authToken) {
        updateContingencies(data.user).then(async () => {
          await sleep(500);
          router.push(accountPath());
        });
      }
    },
    onError: error => {
      updateErrorIntoModal({name: MODAL_LOGIN_PASSCODE, error: error?.message});
      Logger('useUserMutationValidateUserEmail')(error as Error);
    },
  });
}
