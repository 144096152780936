import {useQueryClient} from '@tanstack/react-query';
import {PartnerQueryKey} from '@integrations/partners/usePartnerQuery.set';
import {IPartner} from '@integrations/partners/partners.types';

/*
 * Read operation
 *
 * This returns the "cache" user
 * No api happens here, just a helper to read cache
 *
 * Async/Await not needed here.
 * */
const usePartnerCache = (): IPartner['partner'] => {
  const queryClient = useQueryClient();

  return queryClient.getQueryData(PartnerQueryKey);
};

export default usePartnerCache;
