import {createWithEqualityFn} from 'zustand/traditional';
import {immer} from 'zustand/middleware/immer';
import {SyntheticEvent} from 'react';

/* ----------------------------------------------------------------------- */
/* --------------------- CONSTANTS  -------------------------------------- */
/* ----------------------------------------------------------------------- */
export const APP_STATE_SERVICE_MENU = 'app-state/navigation/APP_STATE_SERVICE_MENU';

/* ----------------------------------------------------------------------- */
/* --------------------- TYPES   ----------------------------------------- */
/* ----------------------------------------------------------------------- */
export type NavigationProps = {
  [APP_STATE_SERVICE_MENU]: boolean;
};

export type NavigationState = {
  isAnyMenuOpen: boolean;
  navigation: NavigationProps;
  prePendMenuByPath: string;

  actions: {
    setNavigationMenu: (arg?: string) => void;
    setNavigationMenuService: (arg?: string) => void;
    setNavigationMenuPathing: (arg?: string) => void;
    resetAllMenus: () => void;
  };

  computed: {
    isAnyMenuOpen: boolean;
  };
};

export type SetNavigationMenu = (event: SyntheticEvent | boolean) => void;

/* ----------------------------------------------------------------------- */
/* ---------------------  NAVIGATION STORE SETUP  ------------------------ */
/* ----------------------------------------------------------------------- */
const resetHelper = (state: NavigationState) => Object.entries(state.navigation).reduce((n, [k, v]) => ({...n, [k]: false}), {});

const useNavigationStore = createWithEqualityFn<NavigationState>()(
  immer((set, get) => ({
    isAnyMenuOpen: false,
    navigation: {[APP_STATE_SERVICE_MENU]: false},
    prePendMenuByPath: `tech-support`,

    /* Actions */
    actions: {
      setNavigationMenuService: () =>
        set(state => {
          state.navigation = {
            ...resetHelper(state),
            [APP_STATE_SERVICE_MENU]: !state.navigation[APP_STATE_SERVICE_MENU],
          };
        }),
      setNavigationMenu: menuName =>
        set(state => {
          const resetAllNavigation = resetHelper(state);
          if (menuName) {
            state.navigation = {
              ...resetAllNavigation,
              [menuName as keyof NavigationProps]: !state.navigation[menuName],
            };
          }
        }),

      setNavigationMenuPathing: (path = `tech-support`) =>
        set(state => {
          state.prePendMenuByPath = path;
        }),

      resetAllMenus: () =>
        set(state => {
          const resetAllNavigation = resetHelper(state);
          state.navigation = resetAllNavigation as NavigationProps;
        }),
    },
    /* COMPUTED STATE GETTERS */
    computed: {
      get isAnyMenuOpen(): boolean {
        return Object.values(get().navigation).some(Boolean);
      },
    },
  })),
);

/* Export Selectors */
export const useNavigationStoreActions = () => useNavigationStore(state => state.actions);
export const useNavigationIsAnyMenuOpen = () => useNavigationStore(state => state.computed.isAnyMenuOpen);
export const useNavigationStoreServiceNavigation = () => useNavigationStore(state => state.navigation[APP_STATE_SERVICE_MENU]);
export const useNavigationStorePrePendMenuByPath = () =>
  useNavigationStore(state => {
    let url = state.prePendMenuByPath;
    url = url.replace(/\/\//g, '/');

    if (!url.startsWith('/')) {
      url = `/${url}`;
    }

    return url;
  });

export default useNavigationStore;
