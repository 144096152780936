/*
 Selector-esque
 * Using useCallback for stability so we can take advantage of react-queries memoization
*/

/*
 * Please postpend all your selectors with 'Selector'
 * */
import {useCallback, useMemo} from 'react';
import get from 'lodash/get';
import {User} from 'types/user.types';
import useUserCache from '@store/queries/user/useUser.cache';

import {getKeysInObject} from '@utils/object';
import {IHash} from 'types/base.types';

/*
 * useUserByKeySelector
 * - Gets a key in the user object
 *
 * If you want just one key value from the User cache.
 * const id = useUserByKey('id');
 * */
export const useUserByKeySelector = (key: string) => useUserCache(useCallback((data: User) => get(data, key), [key]));

/*
 * If you want more than one key of values from the User cache.
 * const { data: { id, accountSubscriptionId } } = useUserByKey(['id', 'account.subscription.id']);
 *
 * */
export const useUserByKeysSelector = <T extends string[]>(keys: T): IHash<any> =>
  useUserCache(useCallback((data: User) => getKeysInObject(keys, data), [keys]));

/**
 * ---------------------------------------------------
 * Plan/Subscription Type Selectors
 * ---------------------------------------------------
 */
export const useUserPlanHolderSelector = () => {
  const accountPlanHolder = useUserByKeySelector('account.planHolder');

  return accountPlanHolder;
};

/* Boolean to tell if hasSubscrption */
export const useUserHasSubscriptionSelector = () => {
  const accountHasSubscription = useUserByKeySelector('account.hasSubscription');

  return accountHasSubscription;
};

/* Does user have a plan or a subscription */
export const useUserHasPlanOrSubscription = (): boolean => {
  const hasPlan = useUserPlanHolderSelector();
  const hasSubscription = useUserHasSubscriptionSelector();

  return Boolean(hasPlan || hasSubscription);
};

/* Get Users plan */
export const useUserPlanSelector = () => {
  const accountPlan = useUserByKeySelector('account.plan');

  return accountPlan;
};

/*
 * This tells us if the user session returned, not that there is a user.
 * */
export const useIsSessionLoadedSelector = () => {
  const hasLoaded = useUserByKeySelector('pristine');

  return !hasLoaded;
};

/**
 * Hand us back either a users pre-bought plan or their carted plan
 *
 * @returns number
 */
export const useUserCartPlanSelector = () => {
  const userPlanId = useUserByKeySelector('account.subscription.planId');

  return useMemo(() => userPlanId, [userPlanId]);
};

/**
 * Hand us back the users Hub information from their "account".
 * Identifies if the user has a Hub or not.
 *
 * @returns number
 */
export const useUserHubSelector = () => {
  const userHub = useUserByKeySelector('account.hub.id');

  return useMemo(() => userHub, [userHub]);
};

/**
 * Hand us back the users id
 *
 * @returns number
 */
export const useUserIdSelector = () => {
  const userID = useUserByKeySelector('id');

  return useMemo(() => userID, [userID]);
};

/**
 * Hand us back the rpt value returned from the user session.
 *
 * @returns string
 */
export const useUserRPTSelector = () => {
  const rpt = useUserByKeySelector('rpt');

  return useMemo(() => rpt, [rpt]);
};

/**
 * Hand us back the users branding subscription partner
 *
 * @returns number
 */
export const useUserSubscriptionPartnerSelector = () => {
  const userSubscriptionPartner = useUserByKeySelector('account.subscription.partner');

  return useMemo(() => userSubscriptionPartner, [userSubscriptionPartner]);
};

/**
 * Hand us back the users authToken
 *
 * @returns number
 */
export const useUserAuthTokenSelector = () => {
  const authToken = useUserByKeySelector('authToken');

  return useMemo(() => authToken, [authToken]);
};
