import {createWithEqualityFn} from 'zustand/traditional';
import {immer} from 'zustand/middleware/immer';
import get from 'lodash/get';

/* ----------------------------------------------------------------------- */
/* --------------------- TYPES ------------------------------------------- */
/* ----------------------------------------------------------------------- */
import {STATUSES} from 'types/component.types';
import {WritableDraft} from 'immer/dist/internal';

export type NotificationSnack = {
  status?: STATUSES;
  content: string;
  id?: number | string;
  template?: ({templateData}: any) => (null | React.JSX.Element) | undefined;
  templateData?: unknown;
  dismissable?: boolean;
  autoClose?: boolean;
};

type FuncTypeSnack = (snack: {
  template?: ({templateData}: any) => (null | React.JSX.Element) | undefined;
  dismissable?: boolean;
  content: string;
  autoClose?: boolean;
  id?: number | string;
  status?: STATUSES;
}) => void;

type SnackState = {
  notificationsSnack: NotificationSnack[];

  actions: {
    addSuccessAppSnackNotice: FuncTypeSnack;
    addErrorAppSnackNotice: FuncTypeSnack;
    addInfoAppSnackNotice: FuncTypeSnack;
    addWarningAppSnackNotice: FuncTypeSnack;
    addAppSnackNotice: FuncTypeSnack;
    deleteAppSnackbarNotification: FuncTypeSnack;
  };
};

/* Helper Functions */
const SnackBarBase = (state: SnackState, snackObject: NotificationSnack) => {
  /* Just set it up for future by using array */
  const {content, template, templateData = {}, id, status, dismissable, autoClose = true} = snackObject;
  const notices: NotificationSnack[] = get(state, 'notificationsSnack', []);
  notices.push({
    content,
    template,
    templateData,
    id,
    status,
    dismissable,
    autoClose,
  });

  return notices;
};

/* ----------------------------------------------------------------------- */
/* ---------------------  SNACK STORE SETUP  ----------------------------- */
/* ----------------------------------------------------------------------- */

const useSnackStore = createWithEqualityFn<SnackState>()(
  immer(set => ({
    notificationsSnack: [],

    /* Actions */
    actions: {
      addSuccessAppSnackNotice: snack =>
        set(state => {
          state.notificationsSnack = SnackBarBase(state, {status: STATUSES.success, ...(snack.dismissable ? {} : {dismissable: true}), ...snack});
        }),

      addErrorAppSnackNotice: snack =>
        set(state => {
          state.notificationsSnack = SnackBarBase(state, {
            status: STATUSES.error,
            ...(snack.dismissable ? {} : {dismissable: true}),
            ...snack,
          });
        }),

      addInfoAppSnackNotice: snack =>
        set(state => {
          state.notificationsSnack = SnackBarBase(state, {status: STATUSES.info, ...(snack.dismissable ? {} : {dismissable: true}), ...snack});
        }),

      addWarningAppSnackNotice: snack =>
        set(state => {
          state.notificationsSnack = SnackBarBase(state, {status: STATUSES.warning, ...(snack.dismissable ? {} : {dismissable: true}), ...snack});
        }),

      addAppSnackNotice: snack =>
        set(state => {
          state.notificationsSnack = SnackBarBase(state, {...(snack.dismissable ? {} : {dismissable: true}), ...snack});
        }),

      deleteAppSnackbarNotification: snack =>
        set(state => {
          let snacks: WritableDraft<NotificationSnack>[] = [];

          if (snack.id) {
            snacks = state.notificationsSnack.filter(s => s.id !== snack.id);
          }

          state.notificationsSnack = snacks;
        }),
    },
  })),
);

export const useSnackStoreActions = () => useSnackStore(state => state.actions);

export default useSnackStore;
