import {createWithEqualityFn} from 'zustand/traditional';
import {immer} from 'zustand/middleware/immer';
import {DefaultPhone} from '@constants/hellotech';
import {getPhoneObject} from '@utils/formatting';
import {TPhone} from 'types/base.types';

/* ----------------------------------------------------------------------- */
/* --------------------- TYPES  ------------------------------------------ */
/* ----------------------------------------------------------------------- */

export type TPhoneState = {
  phone: TPhone;

  actions: {
    setPhoneState: (arg: string) => void;
    clearPhoneState: () => void;
  };
};

/* ----------------------------------------------------------------------- */
/* ---------------------  CHAT STORE SETUP  ------------------------------ */
/* ----------------------------------------------------------------------- */

const useTrackingStore = createWithEqualityFn<TPhoneState>()(
  immer((set, get) => ({
    phone: {title: '', value: '', link: ''},

    /* Actions */
    actions: {
      setPhoneState: (phone: string | TPhone) => {
        set(state => {
          /* Normalize to value/title/tel if its a string/number */
          if (typeof phone === 'string' || typeof phone === 'number') {
            state.phone = getPhoneObject(phone);
          } else {
            state.phone = phone;
          }
        });
      },
      clearPhoneState: () => {
        set(state => {
          state.phone = DefaultPhone;
        });
      },
    },
  })),
);

/* Export Selectors */
export const useTrackingPhone = () => useTrackingStore(state => state.phone);
export const useTrackingActions = () => useTrackingStore(state => state.actions);

export default useTrackingStore;

/**
 * Usage
 * setPhoneState('13104885214')
 *
 * If you want to clear it: clears all values
 * clearPhoneState()
 * or
 * setPhoneState('')
 */
