import {useQuery} from '@tanstack/react-query';
import {PHONE_TRACKING_TYPES, setPhoneNumber} from '@utils/cookies/phone';

/* Client State */
import { useTrackingActions } from '@store/client/store.tracking';

/* utils */
import {isServerSide} from '@utils/environment';
import {normalizeAndSetPartnerQuery} from '@integrations/partners/partners.utils';

/* Types */
import {IPartner} from '@integrations/partners/partners.types';
import {IHash} from 'types/base.types';

/**
 * Lets get the partner data into a visual representation. This is only for the client side rendering.
 * We could abstract this away & make it completely servers side, if our used pages were SSR instead of SSG.
 * We can switch it up, but it would be a small perf hit. Probably better to "wait" and see how all this 'partners' stuff
 * fleshes out with design/partners team etc..
 *
 * Remember: this is a stopgap solution.
 *
 * @param {{id: number, partnerName: string, cobrandedLogo: CoBrandedLogo[], landingSeoName: string, partner_id: number, name: string} | null} partner
 * @param {IHash<any> | undefined} config
 *
 * @returns {UseQueryResult<any, unknown>}
 */

/* Query Key */
export const PartnerQueryKey = ['partner'];

const usePartnerQuerySet = ({partner, config = {}}: {partner: IPartner['partner']; config?: IHash<string>}) => {
  if (isServerSide()) return null;

  /* Client State */
  const {setPhoneState} = useTrackingActions();

  /* Constants */
  const defaultOptions = {};
  const mergedOptions = {...defaultOptions, ...config};

  if (partner && 'phone' in partner && partner.phone !== undefined) {
    let phoneToSave: string = '';
    if (typeof partner.phone === 'object' && 'value' in partner.phone) {
      phoneToSave = partner.phone.value;
    } else if (typeof partner.phone === 'string' || typeof partner.phone === 'number') {
      phoneToSave = partner.phone.toString();
    }

    if (phoneToSave) {
      setPhoneNumber(PHONE_TRACKING_TYPES.LANDING_PHONE, phoneToSave);
      setPhoneState(partner.phone as string);
    }
  }

  return useQuery({
    queryKey: PartnerQueryKey,
    queryFn: () => normalizeAndSetPartnerQuery({partner}),
    ...mergedOptions,
  });
};

export default usePartnerQuerySet;
