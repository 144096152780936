import {getDirectBookingTechId} from '@utils/cookies/directBookingCookie';
import {PartnerBaseAttributes} from '@integrations/partners/partners.types';
import {LAYOUT_PAGE_TYPES} from '@components/PageLayouts/pagelayouts.constants';
import {DEVELOPMENT, PRODUCTION, STAGING} from '@constants/base';
import FrontierDiscountPricing from '@integrations/partners/Modals/FrontierDiscountPricing';

/**
 * These are the phone numbers to some of our partners. They either can't be derived from landing pages or
 * are custom.
 *
 * @type {{samsung: {link: string, title: string, value: string}, cove: {link: string, title: string, value: string}, walmart: {link: string, title: string, value: string}, xfinity: {link: string, title: string, value: string}}}
 */
export const PARTNER_PHONES = {
  cove: {
    link: 'tel:18337521821',
    title: '1-833-752-1821',
    value: '18337521821',
  },
  alder: {
    link: 'tel:18337521821',
    title: '1-833-752-1821',
    value: '18337521821',
  },
  walmart: {
    link: 'tel:18559994699',
    title: '1-855-999-4699',
    value: '18559994699',
  },
  samsung: {
    link: 'tel:18006400206',
    title: '1-800-640-0206',
    value: '18006400206',
  },
  xfinity: {
    link: 'tel:18007536139',
    title: '1-800-753-6139',
    value: '18007536139',
  },
  frontier: {
    link: 'tel:18559770505',
    title: '1-855-977-0505',
    value: '18559770505',
  },
};

/**
 * Some of our partners have a subscription with us. This is the phone number for that subscription.
 * @type {{[key: string]: {link: string, title: string, value: string}}}
 */
export const PARTNER_PHONES_SUBSCRIPTION = {
  walmart: {
    link: 'tel:18556626034',
    title: '1-855-662-6034',
    value: '18556626034',
  },
  frontier: {
    link: 'tel:18559770505',
    title: '1-855-977-0505',
    value: '18559770505',
  },
};

/**
 * This is the partner attributes that are available for each partner.
 * They should define behavior, not conent ala, logo/phone.
 * @type {{showChat: boolean, logoPosition: string, hideNavLinks: boolean, cart: {showCartIcon: boolean, isCobranded: boolean}}}
 *
 * NOTE: many of these could be "walled attributes", but I am not gonna extrapolate at this time. Too many unknowns.
 */
export const basePartnerAttributes: PartnerBaseAttributes = {
  logoPosition: 'right',
  hideNavLinks: true,
  showChat: true,
  htLogoLink: false,
  showCartIcon: true,
  isCobranded: false,
  showFooter: true,
  hideMembershipLink: false,
  themeAssociations: null,
  planAssociations: null,
  planAssociatedPhoneNumber: null,
  footerSiteLinks: false,
  footerLegalLinks: true,
  page: 'precart',
  homeUrl: '',
  /**
   * This is how it should be done. Will update as we move into post cart
   */
  [LAYOUT_PAGE_TYPES.PRECART]: {
    header: '',
    footer: '',
  },
  [LAYOUT_PAGE_TYPES.CART]: {
    header: '',
    footer: '',
  },
  [LAYOUT_PAGE_TYPES.CHECKOUT]: {
    header: '',
    footer: '',
  },
};

/*
 * Walled Garden Cookie
 * LIST OF COOKIES
 * */
export const WalledGardenCookies = [getDirectBookingTechId];

/**
 * These are the data structurs for the promo bar that shows on the landing pages
 * @type {{frontier: {showCtaLink: boolean, ctaAction: (fn: BaseAnyFunction) => any, ctaLabel: string, promoBodyText: string}, ahs: {showCtaLink: boolean, ctaLabel: string, promoBodyText: string}}}
 */
type PromoBarContent = {
  promoBodyText: string;
  showCtaLink: boolean;
  ctaLabel: string;
  ctaLeanMoreAction: boolean;
  Modal: React.FC<any> | null;
};

const LANDINGS_PROMO_BAR_CONTENT: Record<string, PromoBarContent> = {
  ahs: {
    promoBodyText: 'AHS members save 15% on all HelloTech installation and support services.',
    showCtaLink: false,
    ctaLabel: '',
    ctaLeanMoreAction: false,
    Modal: null,
  },
  frontier: {
    promoBodyText: 'Frontier customers save up to 20% on HelloTech installation and support services.',
    showCtaLink: true,
    ctaLabel: 'Learn More',
    ctaLeanMoreAction: true,
    Modal: FrontierDiscountPricing,
  },
};

/**
 * PROMO BAR
 *
 */
export const LANDINGS_PROMO_BAR = new Map([
  [
    STAGING,
    new Map([
      [139821, LANDINGS_PROMO_BAR_CONTENT.ahs],
      [735882, LANDINGS_PROMO_BAR_CONTENT.frontier],
    ]),
  ],
  [
    PRODUCTION,
    new Map([
      [139821, LANDINGS_PROMO_BAR_CONTENT.ahs],
      [1018961, LANDINGS_PROMO_BAR_CONTENT.frontier],
    ]),
  ],
]);

/**
 * FEATURED SERVICES
 *
 */
export const LANDINGS_FEATURED_SERVICES = new Map([
  [
    DEVELOPMENT,
    new Map([
      [735882, [10567, 10581, 10569, 10570, 10577]],
    ]),
  ],
  [
    STAGING,
    new Map([
      [735882, [10567, 10581, 10569, 10570, 10577]],
    ]),
  ],
  [
    PRODUCTION,
    new Map([
      [1018961, [1635, 1728, 1723, 1717, 1726]],
    ]),
  ],
]);

const stagingValue = LANDINGS_PROMO_BAR.get(STAGING);
if (stagingValue !== undefined) {
  LANDINGS_PROMO_BAR.set(DEVELOPMENT, stagingValue);
}

/**
 * Partner Group Subcription key
 */
export const PARTNER_GROUP_SUBSCRIPTION = 'subscription';

/**
 * Some intents are listed as partners, "google adwords". Huh? Not a partner, bug a strategy/template.
 */
export const EXCLUDE_PARTNER_INTENTS = ['google adwords'];
