import Cookies from 'js-cookie';
import {EXPIRY_HOUR} from '@utils/cookies/cookies.constants';

/**
 * --------------------------------------------------------------------
 * landing-phone
 * --------------------------------------------------------------------
 * Set the phone-number cookie, usually via param in app.globalsettings.ts
 * 30 minutes
 *
 * @param {string} phoneNumber
 * --------------------------------------------------------------------
 */
// This is in order of precedence, used int app.cookies-state.ts
export const PHONE_TRACKING_TYPES = {
  /* Third */
  LANDING_PHONE: 'landing-phone',
  /* Second */
  ADWORD_PHONE: 'adword-phone',
  /* First */
  SUBSCRIPTION_PHONE: 'plan-subscriber-phone',
};

/* Why do we have so many phones? landing/ad/subscription - why not just "phone" */

export const setPhoneNumber = (key: string, phoneNumber: string | string[], expires: Date | number = EXPIRY_HOUR) => {
  Cookies.set(key, phoneNumber, {expires});
};

export const getPhoneNumber = (key: string) => Cookies.get(key);
export const removePhoneNumber = () => {
  Cookies.remove(PHONE_TRACKING_TYPES.LANDING_PHONE);
  Cookies.remove(PHONE_TRACKING_TYPES.ADWORD_PHONE);
};
