/*
 *
 * Each Page Will Generally Pass in their own attributes, but we should have a "base".
 * This will be the base for all pages, so that we can have a consistent look and feel,
 * and be able to easily change it in one place.
 * */

/*
  promo: this tells us if we want the promo on the top of the page
  footer: toggle for footer component. This is beyond partners stuff.
  chat: do we want to show the chat component?
  checkout: this identifies the page as a checkout page.
 */
export type TPageLayoutAttributesBase = {
  promo: boolean;
  footer: boolean;
  chat?: boolean;
  checkout?: boolean;
  partnerEnabled?: boolean;
  page?: string;
};

/**
 * This represents what a page can be cutomized with.
 * ex: chat {boolean} - show chat or not
 *     partnerEnabled {boolean} - can we show partner specific stuff in header/footer
 *                                Note: "subscription branding" is not affected by this.
 *
 */
export const PageLayoutAttributesBase: TPageLayoutAttributesBase = {
  promo: true,
  footer: true,
  chat: true,
  checkout: false,
  partnerEnabled: true,
};

/**
 * Our partners (and perhaps standard) can have a number different types of pages.
 * 1. precart - modified "standard" header
 * 2. cart - modified "minimal" header in which the display is center, with no phone etc.
 *
 * @type {{CHECKOUT: string, PRECART: string, CART: string}}
 */
export const LAYOUT_PAGE_TYPES = {
  PRECART: 'precart',
  CART: 'cart',
  CHECKOUT: 'checkout',
};

/*
   LAYOUT TYPES HEADER FOOTER: USED IN STANDARD & PARTNERS
    - standard: standard header/footer
    - partners: partners header/footer
    - cobranded: cobranded/partners header/footer
 */
export const LAYOUT_TYPE_HEADER_FOOTER = {
  STANDARD: 'standard',
  PARTNERS: 'partners',
  COBRANDED: 'cobranded',
};
