import {Modal} from 'ht-styleguide';
import React from 'react';

/**
 * Frontier Discount Pricing Modal
 *
 * NOTE: This modal is only used on the Frontier landing page but we WILL have more
 *       modals in the future, so we need a strategy at that time. Have a command center
 *       to read in the partner and modal type, and render the correct modal.
 *       For now, simply just give the frontier modal.
 *
 * @param learnMoreModalOpen
 * @param setLearnMoreModalOpen
 * @returns {React.JSX.Element}
 * @constructor
 */
type LearnMoreModalProps = {
  learnMoreModalOpen: boolean;
  setLearnMoreModalOpen: (open: boolean) => void;
};
const FrontierDiscountPricing = ({learnMoreModalOpen, setLearnMoreModalOpen}: LearnMoreModalProps): React.JSX.Element => (
  <Modal isVisible={learnMoreModalOpen} hide={() => setLearnMoreModalOpen(false)} header="How discounts work">
    <div>
      <p className="p1">Discounts are applied at checkout</p>
      <div>
        <p className="p1 paddingTop-small2">
          <div className="font-weight-medium">Youtube TV Installation</div>
          $119 for first TV
          <br />
          $60 per additional TV in the same visit
          <br />
        </p>
      </div>

      <div className="paddingTop-small2">
        <p className="p1">
          <div className="font-weight-medium paddingBottom-small">All other services</div>
          <div className="p2 flex flexDirectionRow alignItemsLeft">
            <div className="flex flex1">1 Eligible Service</div>
            <div className="flex flex1">10% off each</div>
          </div>
          <div className="p2 flex flexDirectionRow alignItemsLeft">
            <div className="flex flex1">2+ Eligible Services</div>
            <div className="flex flex1">20% off each</div>
          </div>
        </p>
        <div className="caption n700 paddingTop-small2">
          Restrictions apply. Not available in all areas. Discount applies to regular price and is applied at checkout. May not be combined with other
          offers or discounts. Offer may be terminated or changed at any time.
        </div>
      </div>
    </div>
  </Modal>
);

export default FrontierDiscountPricing;
