import get from 'lodash/get';
import identity from 'lodash/identity';
import {ParsedUrlQuery} from 'querystring';

/*
 * PLAIN OBJECT WITH KEYS
 * Test to see if passed argument is a plain object with at least 1 key.
 * */
export const isPlainObjectWithKeys = (obj: any) => Object.prototype.toString.call(obj) === '[object Object]' && Object.keys(obj).length > 0;

/**
 * getValueByKey
 *
 * @param OBJ - base object we are trying to derive our values
 * @param keys - our key references we want to get our values from
 * @param actionOnResults - function action that acts on result set
 */
export function getValueByKey<T extends object>(OBJ: T, key: string, actionOnResults: Function = identity) {
  const result: (number | string)[] = [];

  if (!OBJ) return OBJ;

  /**
   * Our internal recursive function.
   * This will go thru the response, recursively, and get the value
   * from the key you specify. Obviously, not fool proof in terms of
   * asking for certain keys.
   *
   * @param this - passed context in foreach
   * @param k
   */
  // @ts-ignore
  function recursiveIterator<L extends Record<string, unknown>>(this: L, k: string) {
    const value = this?.[k] ?? null;

    if (value && this && key === k) {
      return result.push(...([] as string[]).concat(value as string));
    }
    if (Array.isArray(value)) {
      return value.forEach(recursiveIterator);
    }
    if (value !== null && typeof value === 'object') {
      return Object.keys(value!).forEach(recursiveIterator, value);
    }

    return result;
  }

  Object.keys(OBJ).forEach(recursiveIterator, OBJ);
  return actionOnResults([...new Set(result)][0]);
}

/**
 *
 * @param response
 * @param options - { title, content } default as error_title & errors
 */
export const deriveResponseError = (
  response: any,
  options: {
    title?: string;
    content?: string;
  } = {}
) => {
  const genericTitleError = options.title || 'Request Error';
  const genericBodyError = options.content || ['We could not process your request at this time.'];

  const title = getValueByKey(response, 'error_title') || genericTitleError;
  const errors = getValueByKey(response, 'errors') || genericBodyError;

  return {
    error_title: title,
    errors: [].concat(errors).join(' '),
  };
};

/*
 * getKeysInObject
 * - Pretty handy. Supply a pathing of a key(s) and it will return in object format:
 *
 * @param keys - Array of strings
 * @param data - Object to traverse to find values from keys
 *
 * If you want more than one key of values from the User cache.
 * const { data: { id, accountSubscriptionId } } = useUserByKey(['id', 'account.subscription.id']);
 * */

/**
 *

 */
export const getKeysInObject = <U extends Array<string>, W = any>(keys: U, data: W) => {
  if (!Array.isArray(keys) || !data || !Object.keys(data).length) return {};

  const objectByKeys = keys.reduce((byKeys, k) => {
    const key = k;
    if (k.includes('.')) {
      const [f, ...rest] = key.split('.');
      const newKey = f.concat(...rest.map((r: string) => `${r.charAt(0).toUpperCase()}${r.substring(1)}`));
      byKeys[newKey] = get(data, k);
    } else {
      byKeys[key] = get(data, k);
    }

    return byKeys;
  }, {});

  return (objectByKeys || {}) as any;
};

/**
 * /**
 * deriveResponseStatus
 * - this will return the response status of an api call
 *
 * @param response
 */
export const deriveResponseStatus = (response: any) => getValueByKey(response, 'status');

/**
 * This lets us know if our passed value is an object AND has keys
 *
 * @param testObject
 */
export const isValidObjectWithKeys = (testObject: any) =>
  Object.prototype.toString.call(testObject) === '[object Object]' && Boolean(Object.keys(testObject).length) && !(testObject instanceof Object);
/**
 * Formats a query string for url
 *
 * @type {string}
 */
export const formatQueryParams = (query: ParsedUrlQuery) =>
  Object.entries(query)
    .reduce((obj, [k, v]) => obj.concat(`${k}=${v}`), [] as string[])
    .join('&');
