import identity from 'lodash/identity';

/**
 * Generalized helpers that don't reall fit with any particular base intent.
 */
/**
 * Create sleeper method akin to java in which once the timing resolves
 * resolve a promise and then you can ".then" off of it. A glorified
 * setTimeout w/o all the type.
 *
 * Usage: sleep(1000).then(action.doSomething())
 * or await sleep etc..
 */
export const sleep = (time: number) => new Promise(resolve => setTimeout(resolve, time));

/**
 * Scroll to the position passed or the elem passed.
 * Pos: number of top position to scroll to
 * elem: id of the element to scroll to
 *
 * @param {number} pos
 */
export const smoothScrollTo = ({pos, elem, elemAdjust = 0}: {pos?: number; elem?: string; elemAdjust?: number}) => {
  if (!elem && !pos) return;

  if (elem) {
    const element = document.getElementById(elem);
    if (element) {
      const position = element.offsetTop - elemAdjust;
      window.scrollTo({top: position, behavior: 'smooth'});
    }
  } else {
    window?.scrollTo({
      top: pos,
      behavior: 'smooth',
    });
  }
};

/**
 * Calculate the min height of a container/grouping display.
 * We see this now on the category page for services.
 *
 * @param types
 * @returns number
 */
export const calculateMinHeight = (
  types: {[key: string]: any}[],
  {
    groupHeight,
    itemHeight,
    byPredicate = identity,
    isMobile,
  }: {groupHeight: number; itemHeight: number; byPredicate?: BaseAnyFunction; isMobile: boolean},
): number =>
  types.reduce((height, type) => {
    const typeHeight = groupHeight;
    const items = (type?.skus || []).filter(byPredicate);
    let itemsHeight;
    if (isMobile) {
      itemsHeight = items.length * itemHeight;
    } else {
      itemsHeight = Math.ceil(items.length / 4) * itemHeight;
    }
    return height + typeHeight + itemsHeight;
  }, 0);
