import {useEffect, useState} from 'react';
import isEmpty from 'lodash/isEmpty';
import {useRouter} from 'next/router';

/* Selectors / Hooks */
import {useUserAuthTokenSelector} from '@store/queries/user/useUser.selector';

/* Utils */
import {Logger} from '@utils/logger';

/* Apis */
import {workflows} from '@api/apis';

/* Actions */
import {useSnackStoreActions} from '@store/client/store.snack';

// Constants
import {WORKFLOW_STATUS} from '@scenes/EV/ev.constants';
import {TEMPLATE_COMPONENTS, TEMPLATE_NAMES} from '@components/UI/Snackbar/snackbar.constants';
import {evPortalPath} from '@constants/paths';

// Selectors

/**
 * List of permitted paths for the workflow.
 * Add more paths to this array as needed.
 *
 * The function `isPermittedPath` uses this array to dynamically create a Regular Expression
 * to test against the current location path. This ensures that the system recognizes
 * the URL as a part of the "walled-garden" workflow experience.
 *
 * The Regular Expression also covers sub-routes, e.g., "/ev/sub-route" will be recognized.
 *
 * Example Usage:
 * --------------
 * To add another permitted path, simply add it to the array like so:
 *
 *  export const PERMITTED_PATHS = ['ev', 'newroute'];
 */
export const PERMITTED_PATHS = ['ev', 'account'];

const isPermittedPath = (path: string) => {
  const joinedPaths = PERMITTED_PATHS.join('|');
  const regex = new RegExp(`^\\/(${joinedPaths})(\\/|$)`);

  return regex.test(path);
};

const DEFAULT_SNACK_CONFIG = {
  template: TEMPLATE_COMPONENTS[TEMPLATE_NAMES.MANAGE_WORKFLOW],
  content: '',
  autoClose: false,
  dismissable: true,
};

export const getSnackConfig = (options = {}) => ({...DEFAULT_SNACK_CONFIG, ...options});

export const fetchLastWorkflowForCurrentUser = async () => {
  try {
    const result = await workflows.last();
    if (result.err) throw result;

    // If user has no workflows, it will return an empty object
    return result.data.workflow;
  } catch (e) {
    Logger('fetchActiveWorkflows')(e as Error);
    return null;
  }
};

/**
 * useWorkflowManager
 *
 * Purpose:
 * --------
 * Manages the workflow experience by creating a "walled-garden" for users actively participating in workflows.
 * The hook ensures that if a user navigates outside of the permitted paths related to active workflows, they
 * will be notified via a snackbar.
 *
 * Important Note:
 * ----------------
 * This hook is specifically designed to manage workflow constraints and notifications for users.
 * It fetches active workflow data from the API and determines whether to show a snackbar based on
 * the user's current route and their involvement in an active workflow.
 */
export const useWorkflowManager = () => {
  const [workflow, setWorkflow] = useState({status: null, token: null});
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [hasShownSnackbar, setHasShownSnackbar] = useState(false);

  /* Hooks */
  const router = useRouter();
  const userAuthToken = useUserAuthTokenSelector();
  const {addSuccessAppSnackNotice} = useSnackStoreActions();

  /* Constants */
  const isUserLoggedIn = userAuthToken ? Boolean(userAuthToken) : false;

  const goToEvPortal = () => {
    const query = {auth_token: userAuthToken};
    const pathname = evPortalPath(workflow.token);

    router.push({
      pathname,
      query,
    });
  };

  const openSnackbarComponent = (opts = {}) => {
    const snackConfig = getSnackConfig({
      templateData: {onLinkClick: goToEvPortal},
      ...opts,
    });

    addSuccessAppSnackNotice(snackConfig);
  };

  const shouldFetchWorkflowData = isUserLoggedIn && !isPermittedPath(router.pathname) && !hasShownSnackbar;

  useEffect(() => {
    const fetchData = async () => {
      const fetchedWorkflow = await fetchLastWorkflowForCurrentUser();
      setWorkflow(fetchedWorkflow);

      const hasActiveWorkflow = !isEmpty(fetchedWorkflow) && ![WORKFLOW_STATUS.COMPLETED, WORKFLOW_STATUS.FEEDBACK].includes(fetchedWorkflow.status);

      if (hasActiveWorkflow) {
        setShowSnackbar(true);
        setHasShownSnackbar(true);
      }
    };

    if (shouldFetchWorkflowData) {
      fetchData();
    }
  }, [shouldFetchWorkflowData]);

  useEffect(() => {
    if (showSnackbar) {
      openSnackbarComponent();
    }

    return () => {
      setShowSnackbar(false);
    };
  }, [showSnackbar]);

  return {workflow, showSnackbar};
};
