import Cookies from 'js-cookie';
import { EXPIRY_HOUR } from '@utils/cookies/cookies.constants';


/**
 * --------------------------------------------------------------------
 * ht_coupon_marketing
 * --------------------------------------------------------------------
 * 1 hour
 *
 * Set the add_coupon_id cookie, usually via param in app.globalsettings.ts
 *
 */
const COUPON_COOKIE_NAME = "_ht_add_coupon_id_";

export const setCouponIdCookie = (coupon: string | string[]) => {
  Cookies.set(COUPON_COOKIE_NAME, coupon, { expires: EXPIRY_HOUR});
}

export const getCouponIdCookie = () => Cookies.get(COUPON_COOKIE_NAME)
