import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {PRODUCTION} from '@constants/base';

function ClientBugsnag() {
  const key = process?.env?.NEXT_PUBLIC_BUGSNAG_KEY ?? 'c68bdfc62f0d779b993b7ad5a0017570';

  Bugsnag.start({
    apiKey: key,
    releaseStage: process.env.NODE_ENV,
    enabledReleaseStages: [PRODUCTION],
    maxBreadcrumbs: 10,
    plugins: [new BugsnagPluginReact()],
  });

  return Bugsnag.getPlugin('react')!.createErrorBoundary(React);
}

export default ClientBugsnag;

export const logWarning = (message: any) => {
  Bugsnag.notify(message, event => {
    event.severity = 'warning';
  });
};
